import React from 'react';
import { useStore } from '../../hooks/zustand';
import styles from './header.module.scss';

function LanguageToggle() {
  const { language, setLanguage } = useStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));

  return (
    <div className={styles.toggle}>
      <button
        className={`${styles.toggleSection} ${
          language === 'en' && styles.active
        }`}
        onClick={() => setLanguage('en')}
      >
        <span>English</span>
      </button>
      <button
        className={`${styles.toggleSection} ${
          language === 'es' && styles.active
        }`}
        onClick={() => setLanguage('es')}
      >
        <span>Español</span>
      </button>
    </div>
  );
}

export default LanguageToggle;
