import React, { useState, useCallback, useEffect } from 'react';
import styles from './WriteItOut.module.scss';
import Title from '../../shared/Title';
import './shredder.scss';
import { useLocked } from '../../hooks/ContinueProvider';

function Shredder({ numberOfPiecesToShredInto, paperWidth, originalPiece }) {
  return Array(numberOfPiecesToShredInto)
    .fill()
    .map((_, i) => {
      const shedPieceWidth = paperWidth / numberOfPiecesToShredInto;
      let animationName = 'spinALittle'; //check
      let animationDelay = 0;
      if (i % 2 === 0) {
        animationName = 'spinALittleAlt';
      } else {
        animationName = 'spinALittle';
      }
      if (i % 3 === 0) {
        animationDelay = 0;
      }
      return (
        <div key={i} className='item-wrap'>
          <div
            className={`item ${animationName} shredding`}
            style={{
              clipPath: `inset(0px ${
                (numberOfPiecesToShredInto - i - 1) * shedPieceWidth
              }px 0 ${i * shedPieceWidth}px)`,
              animationDelay: `0.${animationDelay}s`,
              transformOrigin: `${i * shedPieceWidth}px 125px`,
            }}
          >
            {originalPiece}
          </div>
        </div>
      );
    });
}

function Paper({
  textContent,
  setTextContent,
  numberOfPiecesToShredInto = 15,
  headerText,
  original,
}) {
  const [paperWidth, setPaperWidth] = useState(null);
  const paperRef = useCallback((node) => {
    if (node !== null) {
      setPaperWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const [isShredding, setIsShredding] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTextContent('');
      // setIsShredding(false);
    }, 2500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShredding]);

  const { unlockProgram } = useLocked();

  return (
    <div ref={paperRef} className={`item`}>
      {!isShredding ? (
        <div className='shredWrap'>
          <div className={styles.paperWrap}>
            <div className={styles.lineWrap}>
              <div className={styles.lines}>
                <div className={styles.textGenStyles}>
                  <h3>{headerText}</h3>
                  <textarea
                    onChange={(e) => setTextContent(e.target.value)}
                    value={textContent}
                  ></textarea>
                  <button
                    className={styles.shredButton}
                    onClick={() => {
                      setIsShredding(true);
                      unlockProgram();
                    }}
                  >
                    <span>Shred Me</span>
                  </button>
                </div>
              </div>
              <div className={`${styles.holes} ${styles.holeTop}`}></div>
              <div className={`${styles.holes} ${styles.holeMiddle}`}></div>
              <div className={`${styles.holes} ${styles.holeBottom}`}></div>
            </div>
          </div>
          <i className='far fa-times'></i>
        </div>
      ) : null}
      <div className='animation-assets'>
        {original && isShredding && paperWidth ? (
          <Shredder
            numberOfPiecesToShredInto={numberOfPiecesToShredInto}
            paperWidth={paperWidth}
            originalPiece={
              <Paper
                isShredding={false}
                textContent={textContent}
                setTextContent={setTextContent}
                numberOfPiecesToShredInto={numberOfPiecesToShredInto}
                headerText={headerText}
                original={false}
              />
            }
          />
        ) : null}
      </div>
    </div>
  );
}

function WriteItOut() {
  const [content, setContent] = useState('');
  return (
    <>
      <Title>
        <h1>Write It Out</h1>
      </Title>
      <p className={'prompt'}>
        <b>Instructions:</b> Write about a time when you have been bullied, have
        seen bullying, or have heard about bullying. Then click “Shred Me”.
      </p>
      <div className={styles.fireWrap}>
        <Paper
          textContent={content}
          setTextContent={setContent}
          headerText={'Add your Response Below'}
          original={true}
        />
      </div>
    </>
  );
}
export default WriteItOut;
