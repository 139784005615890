import { StylesContext } from '@material-ui/core/node_modules/@material-ui/styles';
import React from 'react';
import Title from '../../shared/Title';
import styles from './WhoToTurnTo.module.scss';
import Questionaire from '../../shared/Questionaire';
import { useLocked } from '../../hooks/ContinueProvider';

const WhoToTurnTo = () => {
  const { unlockProgram } = useLocked();

  return (
    <div>
      <Title isModal>
        <h1>Who To Turn To</h1>
      </Title>
      <p className={styles.banner}>
        If you see physical bullying, you should always turn that over to an
        adult that you feel comfortable talking to. You should not get directly
        involved because you could put yourself in a situation that isn’t safe.
        Read the following scenarios and choose the best person to turn the
        situation over to.
      </p>
      <section className={`${styles.sectionWrap} ${styles.firstItem}`}>
        <h2 className={styles.subHeader}>Scenario 1 - Physical Fight </h2>
        <div>
          <p className={styles.scenario}>
            A pitcher on the school baseball team has been taunting and teasing
            another player. On the field during practice one day, you see the
            pitcher hit that player with a pitch. It looked intentional to you,
            and it looked to you like the batter thought so, too. The coach
            didn’t see the pitch or the batter’s reaction. Who do you turn it
            over to?
          </p>
          <div className={styles.scenarioWrap}>
            <Questionaire
              onClick={unlockProgram}
              modalText={{
                title: 'Yes!',
                content:
                  'The baseball coach would be a good choice in this situation. ',
              }}
              buttonType='thinButton'
              buttonText='An Adult at School'
            />
            <Questionaire
              modalText={{
                title: 'You are on the right track!',
                content:
                  'It’s always a good idea to tell your parents about bullying, but an adult at school could handle this directly.',
              }}
              buttonType='thinButton'
              buttonText='Parent'
            />
            <Questionaire
              modalText={{
                title: 'No',
                content:
                  'A friend can provide you with support but won’t be able to do anything to stop  this situation. A friend could go with you to turn the situation over to an adult.',
              }}
              buttonType='thinButton'
              buttonText='A Friend'
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhoToTurnTo;
