import React from 'react'; 
import {Link} from   "react-router-dom";
function touchable  (props){
    // I want props
    // I wat a link prop that is an array of objects with two key value pairs of link and text 

    let renderedLinks = props.link.map (function (link, index){
    return (<div key = {index}>
        <Link  to = {link.link} >
            <h4 className= "touchableLinks">
                {link.text}
            </h4>
        </Link >
        <br/>
        </div>
    )
    });
    return (

<>     <div className="modalContent ">

<h1>
  {props.text.title}
</h1>

{renderedLinks}


</div></>

    )
}

export default touchable;