import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';

const FireWorksContext = createContext();

export const useFireworks = () => {
  return useContext(FireWorksContext);
};

const FireWorksProvider = ({ children }) => {
  const [explosion, setExplosion] = useState(false);

  useEffect(() => {
    if (explosion) {
      setTimeout(() => {
        setExplosion(false);
      }, 3500);
    }
  }, [explosion]);
  return (
    <FireWorksContext.Provider value={{ explosion, setExplosion }}>
      {children}
    </FireWorksContext.Provider>
  );
};

export default FireWorksProvider;
