/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Questionaire from '../../shared/Questionaire.jsx';
import Title from '../../shared/Title.jsx';
import SpeechBubble from '../../shared/SpeechBubble.jsx';
import Trainer from '../../shared/Trainer.jsx';
import { useLocked } from '../../hooks/ContinueProvider.jsx';
import { TimelineLite } from 'gsap';
import { useStore } from '../../../hooks/zustand.js';

function BullyTypeSelector(props) {
  const { unlockProgram } = useLocked();
  const [buttons, setButtons] = useState(
    props.data.map((item) => ({ ...item, clicked: false }))
  );

  const handleClick = (itemTitle) => {
    const updatedButtons = buttons.map((button) => {
      if (button.title === itemTitle) {
        return { ...button, clicked: true };
      } else {
        return button;
      }
    });
    setButtons(updatedButtons);
    if (updatedButtons.every((button) => button.clicked)) {
      unlockProgram();
    }
  };

  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  return (
    <>
      <Title>
        <h1>Types of Bullying</h1>
      </Title>
      <div className='card-container split-even'>
        <div className='negSpeechWrap'>
          <div>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>
                    There are four different types of bullying that we will
                    discuss. Please click on each button to continue.
                  </div>
                </SpeechBubble>
              </div>
              <Trainer teacher={4} />
            </div>
          </div>
        </div>
        <div className='intro5Box'>
          <div className='questionaireBox '>
            {props.data.map((item, index) => (
              <Questionaire
                key={index}
                modalText={item}
                styleName='intro2'
                buttonType='options+'
                buttonText={item.title}
                onClick={() => handleClick(item.title)}
                audio={item.audio}
                disabled={!allSoundsPlayed}
                hasBeenClicked={buttons[index].clicked}
                img={
                  <img
                    alt='Physical bullying '
                    className='bullyTypeImage'
                    src={require(`../../../assets/images/typesofbullying/${item.imgURL}`)}
                  />
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default BullyTypeSelector;
