import React, { useEffect, useState } from 'react';
import Questionaire from '../../shared/Questionaire.jsx';
import '../../../stylesheets/main.scss';
import SpeechBubble from '../../shared/SpeechBubble.jsx';
import Trainer from '../../shared/Trainer.jsx';
import Title from '../../shared/Title.jsx';
import { useLocked } from '../../hooks/ContinueProvider.jsx';
import { useStore } from '../../../hooks/zustand.js';

function Intro5({ data }) {
  const { unlockProgram } = useLocked();
  const [buttons, setButtons] = useState(
    data.map((item) => ({ ...item, clicked: false }))
  );

  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  const handleClick = (itemTitle) => {
    const updatedButtons = buttons.map((button) => {
      if (button.title === itemTitle) {
        return { ...button, clicked: true };
      } else {
        return button;
      }
    });
    setButtons(updatedButtons);
    if (updatedButtons.every((button) => button.clicked)) {
      unlockProgram(false);
    }
  };

  return (
    <>
      <Title>
        <h1 id='intro5Title'>
          Negative Consequences that are Associated with Bullying
        </h1>
      </Title>
      <div className='card-container split-even'>
        <div className='negSpeechWrap'>
          <div>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>
                    Here are some explanations of the negative consequences of
                    bullying. Please click on each button before continuing to
                    the next page.
                  </div>
                </SpeechBubble>
              </div>
              <Trainer teacher={1} />
            </div>
          </div>
        </div>

        <div className='intro5Box'>
          <div className='questionaireBox '>
            {/* <button onClick = {setState(true)}></button> */}
            <div className='button-wwyd-wrap'>
              {data.map((item, index) => (
                <Questionaire
                  key={index}
                  onClick={() => handleClick(item.title)}
                  modalText={item}
                  styleName='intro2'
                  buttonType='options+'
                  buttonText={item.title}
                  audio={item.audio}
                  disabled={!allSoundsPlayed}
                  hasBeenClicked={buttons[index].clicked}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Intro5;
