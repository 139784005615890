import React from 'react';
import teacher from '../../assets/images/teacher-01.png';
import teacher1 from '../../assets/images/teacher-images/teacher1.jpg';
import teacher2 from '../../assets/images/teacher-images/teacher2.png';
import teacher3 from '../../assets/images/teacher-images/teacher3.png';
import teacher4 from '../../assets/images/teacher-images/teacher4.png';
import teacher5 from '../../assets/images/teacher-images/teacher5.png';
import fullTeacher from '../../assets/images/teacher-images/full-teacher.png';

const Trainer = (props) => {
  let imgSrc;

  switch (props.teacher) {
    case 1:
      imgSrc = teacher1;
      break;
    case 2:
      imgSrc = teacher2;
      break;
    case 3:
      imgSrc = teacher3;
      break;
    case 4:
      imgSrc = teacher4;
      break;
    case 5:
      imgSrc = teacher5;
      break;
    default:
      imgSrc = teacher1;
      break;
  }

  return (
    <div>
      <img className='teacherImage' alt='trainer' src={imgSrc} />
    </div>
  );
};

export default Trainer;
