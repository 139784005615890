/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { gsap } from 'gsap';

function SpeechBubble(props) {
  //    props  = tween = '{key: 'value' }',  children,
  useEffect(() => {
    gsap.from('.speech-bubble, .speech-bubble-shadow ', props.tween);
  }, []);

  // gsap.from('.speech-bubble-modal, .speech-bubble-shadow-modal',
  // props.tween)

  return (
    <>
      {props.type === 'question' ? (
        // basic bubble mainNav
        <>
          <div className='speech-bubble'>
            <div className='arrow bottom right shadow'></div>
            <div className='speech-text '>{props.children}</div>
          </div>

          <div className='speech-bubble-shadow '>
            <div className='arrow bottom right shadow'></div>
          </div>
        </>
      ) : //  question bubble in the modal
      props.type === 'question-modal' ? (
        <>
          <div className='speech-bubble-modal'>
            <div className='arrow bottom right shadow '></div>
            <div className='speech-text '>{props.children}</div>
          </div>

          <div className='speech-bubble-shadow-modal '>
            <div className='arrow bottom right shadow '></div>
          </div>
        </>
      ) : //   answer bubble in the modal
      props.type === 'answer-modal' ? (
        <>
          <div className='speech-bubble-modal'>
            <div className='arrow bottom right shadow '></div>
            <div className='speech-text'>{props.children}</div>
          </div>

          <div className='speech-bubble-shadow-modal '>
            <div className='arrow bottom right shadow'></div>
          </div>
        </>
      ) : props.type === 'custom1' ? (
        <>
          <div>
            <img
              className='customSpeech1'
              src={require('../../assets/images/4x/speech@4x.png')}
              alt='speechbubble'
            />
          </div>
          <div className='customSpeech1Header'></div>
          <div className='customSpeech1Text'>{props.children}</div>
        </>
      ) : (
        //    default case here
        <>
          <div
            className={`speech-bubble ${
              props.isCorrect ? props.isCorrect : ''
            }`}
            key={props.keyForRemount}
          >
            <div className='arrow bottom right shadow'></div>
            <div className='speech-text'>{props.children}</div>
          </div>

          <div className='speech-bubble-shadow '>
            <div className='arrow bottom right shadow'></div>
          </div>
        </>
      )}
    </>
  );
}

export default SpeechBubble;
