import React from 'react';
import AudioControls from './AudioControls';
import { useStore } from '../../hooks/zustand';

function Title(props) {
  const { isLoaded } = useStore((state) => {
    return {
      isLoaded: state.audioArray.length > 0,
    };
  });

  return (
    <div id='titleParent'>
      <div className='flex-title'>
        {isLoaded && !props.isModal && (
          <div className='audioButton hidden'></div>
        )}

        {props.children}
        {!props.isModal && isLoaded && <AudioControls />}
      </div>
    </div>
  );
}
export default Title;
