import React from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

const STACStrategies = () => {
  useUnlockAfterAudioPlays();
  return (
    <>
      <Title>
        <h1>The STAC Strategies </h1>
      </Title>
      <div className='bullet-img-wrap'>
        <FadeInBulletPointsWithAudio />
        <div className='img-wrap'>
          <img alt='bullying' src='/boosterSlideTwo.png' />
        </div>
      </div>
    </>
  );
};

export default STACStrategies;
