/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../../shared/buttons.jsx';
import Questionaire from '../../shared/Questionaire.jsx';
import { gsap, TimelineLite } from 'gsap';
import Assistants from './assistants';
import Defenders from './defenders';
import Trainer from '../../shared/Trainer.jsx';
import SpeechBubble from '../../shared/SpeechBubble.jsx';
import Reinforcers from './reinforcers';
import Outsiders from './outsiders';
import Title from '../../shared/Title.jsx';
import ButtonBox from '../../shared/ButtonBox.jsx';
import { useLocked } from '../../hooks/ContinueProvider.jsx';
import { useStore } from '../../../hooks/zustand.js';

function Selector() {
  const { unlockProgram } = useLocked();
  const tlAssist = gsap.timeline({ paused: true });
  tlAssist.to('#selector', 0.5, { x: -1500, autoAlpha: 0 });
  tlAssist.to('#assist', 0.5, { left: '10%', autoAlpha: 1 });

  const tlReinforcers = gsap.timeline({ paused: true });
  tlReinforcers.to('#selector', 0.5, { x: -1500, autoAlpha: 0 });
  tlReinforcers.to('#reinforcers', 0.5, { left: '10%', autoAlpha: 1 });

  const tlOutside = gsap.timeline({ paused: true });
  tlOutside.to('#selector', 0.5, { x: -1500, autoAlpha: 0 });
  tlOutside.to('#outsider', 0.5, { left: '10%', autoAlpha: 1 });

  const tlDefend = gsap.timeline({ paused: true });
  tlDefend.to('#selector', 0.5, { x: -1500, autoAlpha: 0 });
  tlDefend.to('#defend', 0.5, { left: '10%', autoAlpha: 1 });

  const assistRevert = gsap.timeline({ paused: true });
  assistRevert.to('#assist', 0.5, { left: '2000px', autoAlpha: 0 });
  assistRevert.to('.practice-time', 0, { top: '75px', right: '4vw' });
  assistRevert.to('#selector', 0.5, { x: 0, autoAlpha: 1 });

  const reinforceRevert = gsap.timeline({ paused: true });
  reinforceRevert.to('#reinforcers', 0.5, { left: '2000px', autoAlpha: 0 });
  reinforceRevert.to('.practice-time', 0, { top: '75px', right: '4vw' });
  reinforceRevert.to('#selector', 0.5, { x: 0, autoAlpha: 1 });

  const defendersRevert = gsap.timeline({ paused: true });
  defendersRevert.to('#defend', 0.5, { left: '2000px', autoAlpha: 0 });
  defendersRevert.to('.practice-time', 0, { top: '75px', right: '4vw' });
  defendersRevert.to('#selector', 0.5, { x: 0, autoAlpha: 1 });

  const outsidersRevert = gsap.timeline({ paused: true });
  outsidersRevert.to('#outsider', 0.5, { left: '2000px', autoAlpha: 0 });
  outsidersRevert.to('.practice-time', 0, { top: '75px', right: '4vw' });
  outsidersRevert.to('#selector', 0.5, { x: 0, autoAlpha: 1 });

  const [progress, changeProgress] = useState([]);
  const clickProgress = (number) => {
    let array = progress;
    //push if number is not in array
    if (array.includes(number)) return;
    array.push(number);
    console.log(progress);
    changeProgress(array);
    //if all are clicked alert wicca spring eternally
    if (progress.length === 4) {
      unlockProgram();
    }
  };
  const select = (component) => {
    // this is what will select the animation to move a component off screen and have the
    // inputed component animate onto the page
    switch (component) {
      case 'assistants':
        clickProgress(1);
        tlAssist.play();
        break;
      case 'reinforcers':
        clickProgress(2);
        tlReinforcers.play();
        break;
      case 'defenders':
        clickProgress(4);
        tlDefend.play();
        break;
      case 'outsiders':
        clickProgress(3);
        tlOutside.play();
        break;
      default:
        console.log('error: no switch pointer');
    }
  };

  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  return (
    <>
      <>
        <Title>
          <h1>Bystander Roles</h1>
        </Title>
        <p className='introDef'>
          Bystanders are students who see bullying happen to someone else at
          school or online.
        </p>
        <div className='card-container split-even'>
          <div className='negSpeechWrap'>
            <div>
              <div className='teacherContainer'>
                <div className='mainNavBubble'>
                  <SpeechBubble tween={{}}>
                    <div>
                      There are four different types of bystanders that we are
                      going to talk about. Please click on each button to learn
                      about the different types of bystander roles.
                    </div>
                  </SpeechBubble>
                </div>
                <Trainer teacher={3} />
              </div>
            </div>
          </div>
          <div className='intro5Box'>
            <div className='questionaireBox '>
              {/* <button onClick = {setState(true)}></button> */}
              <Questionaire
                modalText={{
                  title: 'Assistants',
                  list: [
                    'Assistants are students who intentionally help the student who is doing the bullying.',
                    'Assistants actively join in with the bully by doing things like pushing the target along with the bully, joining in with insults, forwarding text messages, and repeating rumors.',
                  ],
                }}
                styleName='intro2'
                onClick={() => select('assistants')}
                buttonType='options+'
                buttonText='Assistants'
                audio='/audio/bystander-roles/assistants.mp3'
                disabled={!allSoundsPlayed}
                hasBeenClicked={progress.includes(1) ? true : false}
                img={
                  <img
                    alt='Assistants'
                    className='bullyTypeImage'
                    src={require('../../../assets/images/sketches/assistants.png')}
                  />
                }
              />
              <Questionaire
                modalText={{
                  title: 'Reinforcers',
                  list: [
                    'Reinforcers are students who are not directly involved in bullying another student, like the assistants who intentionally help the bully.',
                    'Some students who act as reinforcers don’t agree with the bullying. But just by watching, they are encouraging the bully because students who bully like to have a peer audience.',
                  ],
                }}
                styleName='intro2'
                onClick={() => select('reinforcers')}
                buttonType='options+'
                buttonText='Reinforcers'
                audio='/audio/bystander-roles/reinforcers.mp3'
                disabled={!allSoundsPlayed}
                hasBeenClicked={progress.includes(2) ? true : false}
                img={
                  <img
                    alt='Reinforcers'
                    className='bullyTypeImage'
                    src={require('../../../assets/images/sketches/reinforcers.png')}
                  />
                }
              />
              <Questionaire
                modalText={{
                  title: 'Outsiders',
                  list: [
                    <>
                      Outsiders are students who don't take sides. They usually{' '}
                      <b>look away or walk away</b> from the situation because
                      they do not want to get involved in any way.
                    </>,
                    <>
                      Sometimes students don’t agree with what is happening, but
                      they don’t know what to do to help. So, they just try to
                      get away from the situation or ignore it and hope that it
                      stops.
                    </>,
                    'These students usually want to get away from the bullying situation as fast as they can.',
                  ],
                }}
                styleName='intro2'
                buttonType='options+'
                buttonText='Outsiders'
                audio='/audio/bystander-roles/outsiders.mp3'
                disabled={!allSoundsPlayed}
                onClick={() => select('outsiders')}
                hasBeenClicked={progress.includes(3) ? true : false}
                img={
                  <img
                    alt='Outsiders'
                    className='bullyTypeImage'
                    src={require('../../../assets/images/sketches/outsiders.png')}
                  />
                }
              />
              <Questionaire
                modalText={{
                  title: 'Defenders',
                  list: [
                    'Defenders are students who do something to stop the bullying or to help the target in some way.',
                    'Sometimes defenders will be a friend to the target after the bullying situation is over.',
                    'The goal for this training is to teach you 4 STAC strategies you can use when you see bullying so that you can act as a defender and make a positive difference at school.',
                  ],
                }}
                styleName='intro2'
                buttonType='options+'
                buttonText='Defenders'
                onClick={() => select('defenders')}
                hasBeenClicked={progress.includes(4) ? true : false}
                audio='/audio/bystander-roles/defenders.mp3'
                disabled={!allSoundsPlayed}
                img={
                  <img
                    alt='Defenders'
                    className='bullyTypeImage'
                    src={require('../../../assets/images/sketches/defenders.png')}
                  />
                }
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
}
export default Selector;
