import { countArray } from './components/pages/mainprogram/mainProgramItems';
import { routesArray } from './components/pages/booster/BoosterOne';

export const getCurrentPageInfo = (url, isBooster = false) => {
  const arr = isBooster ? routesArray : countArray;
  console.log('check arr', arr);
  const index = arr.findIndex((item) => item.url === `${url}`);
  console.log('ionmde', index);
  const info = arr[index];
  console.log('check info', info);
  return {
    info,
    index,
  };
};
