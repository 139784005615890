import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { countArray } from '../pages/mainprogram/mainProgramItems';

const ContinueContext = createContext(null);

export const LockedProvider = ({ children }) => {
  const [locked, setLocked] = useState(false);
  const unlockProgram = () => {
    setLocked(false);
  };
  const lockProgram = () => {
    setLocked(true);
  };
  const { activity } = useParams();
  //needs to be changed to remove all avatars and we actually want to make setLocked true
  //when the params change the useEffect will run and set the locked state to the locked value of the current page
  useEffect(() => {
    const currentPage = countArray.find((item) => item.url === `${activity}`);
    if (currentPage && currentPage.url !== 'all-avatars') {
      if (currentPage.locked) {
        console.log('locked333', currentPage.locked);
        setLocked(currentPage.locked);
      } else {
        unlockProgram();
      }
    }
  }, [activity]);

  return (
    <ContinueContext.Provider
      value={{
        locked,
        unlockProgram,
        lockProgram,
      }}
    >
      {children}
    </ContinueContext.Provider>
  );
};

export const useLocked = () => useContext(ContinueContext);
