import React from 'react';

const PersonSVG = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill-rule='nonzero'
      viewBox='67 0 125 256'
    >
      <g
        fill-opacity='0'
        fill-rule='nonzero'
        stroke='none'
        strokeWidth='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        strokeMiterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path d='M0,256v-256h256v256z' id='bgRectangle' />
      </g>
      <g
        fill-rule='nonzero'
        strokeWidth='2'
        stroke-linecap='butt'
        stroke-linejoin='round'
        strokeMiterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <path
          transform='scale(5.12,5.12)'
          d='M31,7.5c0,3.03125 -2.46875,5.5 -5.5,5.5c-3.03125,0 -5.5,-2.46875 -5.5,-5.5c0,-3.03125 2.46875,-5.5 5.5,-5.5c3.03125,0 5.5,2.46875 5.5,5.5zM30.5,14c3.03125,0 5.5,2.46875 5.5,5.5v12.5c0,1.10547 -0.89844,2 -2,2c-1.10156,0 -2,-0.89453 -2,-2v-9.34375c0,-0.26953 -0.23047,-0.5 -0.5,-0.5c-0.26953,0 -0.5,0.23047 -0.5,0.5v22.65625c0,1.5625 -0.84375,2.6875 -2.40625,2.6875c-1.38281,0 -2.42578,-1.02344 -2.5625,-2.40625c-0.00391,-0.00781 -0.02734,0.00781 -0.03125,0v-13.125c0,-0.27734 -0.22266,-0.5 -0.5,-0.5c-0.27734,0 -0.5,0.22266 -0.5,0.5v12.84375c0,1.53906 -1.11328,2.6875 -2.59375,2.6875c-1.56641,0 -2.40625,-1.125 -2.40625,-2.6875v-22.84375c0,-0.26953 -0.23047,-0.5 -0.5,-0.5c-0.26953,0 -0.5,0.23047 -0.5,0.5v9.53125c0,1.10547 -0.89844,2 -2,2c-1.10156,0 -2,-0.89453 -2,-2v-12.5c0,-3.03125 2.46875,-5.5 5.5,-5.5z'
          id='strokeMainSVG'
        />
      </g>
      <g
        fill-rule='nonzero'
        stroke='none'
        strokeWidth='1'
        stroke-linecap='butt'
        stroke-linejoin='miter'
        strokeMiterlimit='10'
        stroke-dasharray=''
        stroke-dashoffset='0'
        font-family='none'
        font-weight='none'
        font-size='none'
        text-anchor='none'
      >
        <g transform='scale(5.12,5.12)'>
          <path d='M25.5,2c-3.03125,0 -5.5,2.46875 -5.5,5.5c0,3.03125 2.46875,5.5 5.5,5.5c3.03125,0 5.5,-2.46875 5.5,-5.5c0,-3.03125 -2.46875,-5.5 -5.5,-5.5zM20.5,14c-3.03125,0 -5.5,2.46875 -5.5,5.5v12.5c0,1.10547 0.89844,2 2,2c1.10156,0 2,-0.89453 2,-2v-9.53125c0,-0.26953 0.23047,-0.5 0.5,-0.5c0.26953,0 0.5,0.23047 0.5,0.5v22.84375c0,1.5625 0.83984,2.6875 2.40625,2.6875c1.48047,0 2.59375,-1.14844 2.59375,-2.6875v-12.84375c0,-0.27734 0.22266,-0.5 0.5,-0.5c0.27734,0 0.5,0.22266 0.5,0.5v13.125c0.00391,0.00781 0.02734,-0.00781 0.03125,0c0.13672,1.38281 1.17969,2.40625 2.5625,2.40625c1.5625,0 2.40625,-1.125 2.40625,-2.6875v-22.65625c0,-0.26953 0.23047,-0.5 0.5,-0.5c0.26953,0 0.5,0.23047 0.5,0.5v9.34375c0,1.10547 0.89844,2 2,2c1.10156,0 2,-0.89453 2,-2v-12.5c0,-3.03125 -2.46875,-5.5 -5.5,-5.5z' />
        </g>
      </g>
    </svg>
  );
};

export default PersonSVG;
