import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import './slider.scss';
import { gsap } from 'gsap';
import Title from '../../shared/Title';
import checkPNG from './check.png';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { useAvatar } from '../../../hooks/AvatarContext';
import { autoplayEnum, useStore } from '../../../hooks/zustand';
import { useLocked } from '../../hooks/ContinueProvider';

// AvatarSlider.propTypes = {};

const AvatarSlider = ({ storySlides, responseOptions, title }) => {
  const { load, eject, allSoundsPlayed, hasAudio } = useStore((store) => {
    return {
      load: store.load,
      eject: store.eject,
      allSoundsPlayed: store.allSoundsPlayed,
      hasAudio: store.audioArray.length > 0,
      audiArr: store.audioArray,
    };
  });

  const history = useHistory();
  const [responseSlides, setResponseSlides] = useState(
    responseOptions.map((slide) => ({ ...slide, id: uuidv4() }))
  );

  const { language } = useStore((state) => ({
    language: state.language,
  }));

  //what slide is active
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  //set this to true when you want autoswiping to the next sl
  const [triggerSwipe, setTriggerSwipe] = useState(false); // [1
  const buttonsRef = useRef([]);
  const swiperRef = useRef(null);
  const { selectedAvatar } = useAvatar();

  if (!selectedAvatar) {
    history.push('/main-program/all-avatars');
  }

  const baseSlides = useMemo(
    () => storySlides.map((slide) => ({ ...slide, id: uuidv4() })),
    [storySlides]
  );

  const { unlockProgram } = useLocked();

  const handleClick = (slidedD) => {
    // console.log(slideInfo.baseSlides.concat(newSlides), 'newSlides');
    const slideInfo = responseSlides.map((slide) => {
      if (slide.id === slidedD) {
        return { ...slide, active: true, completed: true };
      } else {
        return { ...slide, active: false };
      }
    });

    //check if all slides are completed
    const allSlidesCompleted = slideInfo.every(
      (slide) => slide.completed === true
    );

    if (allSlidesCompleted) {
      unlockProgram();
    }

    setResponseSlides(slideInfo);
    setTriggerSwipe(activeSlideIndex + 1);
  };

  // go to slide when triggerSwipe is true
  useEffect(() => {
    if (triggerSwipe) {
      swiperRef.current.swiper.slideTo(triggerSwipe);
      setTriggerSwipe(false);
    }
  }, [triggerSwipe]);

  const wwydSlideIndex = useMemo(
    () => baseSlides.findIndex((slide) => slide.type === 'wwyd'),
    [baseSlides]
  );

  useEffect(() => {
    //if this is the wwyd slide then pop up the buttons one by one

    const oneButtonHasBeenClicked = responseSlides.some(
      (item) => item.completed === true
    );

    if (activeSlideIndex === wwydSlideIndex && !oneButtonHasBeenClicked) {
      const buttons = buttonsRef.current;
      buttons.forEach((button, index) => {
        gsap.from(button, {
          opacity: 0,
          duration: 0.5,
          delay: index * 0.25,
          onComplete: () => {
            button.style.opacity = 1;
          },
        });
      });
    }
  }, [activeSlideIndex, responseSlides, wwydSlideIndex]);

  const getSlidesToRender = () => {
    const isAnActiveResponse = responseSlides.some(
      (slide) => slide.active === true
    );
    if (!isAnActiveResponse) {
      return baseSlides;
    } else {
      return [
        ...baseSlides,
        ...responseSlides
          .filter((slide) => slide.active === true)
          .flatMap((s) => s.slides),
      ];
    }
  };

  useEffect(() => {
    const slides = getSlidesToRender();
    console.log(slides[0].audio, slides, 'slider');
    if (!slides[0].audio) return;
    load(slides[0].audio, autoplayEnum.ALL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetSlides = () => {
    const newSlides = responseSlides.map((slide) => ({
      ...slide,
      active: false,
    }));
    setResponseSlides(newSlides);
    setTriggerSwipe(wwydSlideIndex + 1);
  };

  const replaceAvatar = (url) => {
    if (!selectedAvatar) return url;
    const newURL = url.replace('b1', selectedAvatar);
    if (language === 'en') return newURL;
    const spanishUrl = newURL.replace(/(\d+)(\.png)$/, '$1_es$2');
    return spanishUrl;
  };

  const handleSlideChange = (index) => {
    setActiveSlideIndex(index);
    eject();
    const slides = getSlidesToRender();
    if (slides[index].audio) {
      load(slides[index].audio, autoplayEnum.ALL);
    }
  };

  const selectionSlide = baseSlides.length - 1;

  console.log('allSoundsPlayed', hasAudio);

  return (
    <div>
      <Title>
        <h1>{title}</h1>
      </Title>
      <div
        className={`mySwiper ${
          !hasAudio || allSoundsPlayed || selectionSlide === activeSlideIndex
            ? ''
            : 'swiper-no-swiping'
        }`}
      >
        <Swiper
          className='mySwiper'
          ref={swiperRef}
          navigation={true}
          spaceBetween={30}
          centeredSlides
          onSlideChange={(swiper) => handleSlideChange(swiper.activeIndex)}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination]}
        >
          {getSlidesToRender().map((article) => {
            if (article.type === 'wwyd') {
              return (
                <SwiperSlide key={article.id}>
                  <div className='slide-wrap '>
                    <img
                      src={replaceAvatar(article.image.url)}
                      alt={article.title}
                      className='wwydImg'
                    />
                    <div className='wwyd-wrap avatar-buttons'>
                      <h2>Choose a Strategy!</h2>
                      <div className='button-wwyd-wrap'>
                        {responseSlides.map((item, index) => {
                          return (
                            <button
                              key={index}
                              ref={(el) => (buttonsRef.current[index] = el)}
                              onClick={() => handleClick(item.id)}
                              className={item.completed ? 'completed' : ''}
                            >
                              {language === 'es' ? item.esTitle : item.title}
                              {item.completed && (
                                <img src={checkPNG} alt='completed' />
                              )}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }
            if (article.type === 'end') {
              return (
                <SwiperSlide key={article.id}>
                  <div className='slide-wrap'>
                    <img
                      src={replaceAvatar(article.image.url)}
                      alt={article.title}
                    />
                    <div className='teacher-text'>
                      <div className='overflow-wrap'>
                        <p>{article.text}</p>
                        <button
                          disabled={!allSoundsPlayed}
                          className='try-another'
                          onClick={resetSlides}
                        >
                          Try Another Strategy!
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            } else {
              return (
                <SwiperSlide key={article.id}>
                  <div className='slide-wrap'>
                    <img
                      src={replaceAvatar(article.image.url)}
                      alt={article.title}
                    />
                  </div>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default AvatarSlider;
