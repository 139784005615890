import React from 'react';
import Title from '../../shared/Title';
import { Fade } from 'react-bootstrap';
import Badge from './Badge';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

function Accompany() {
  const allSoundsPlayed = useUnlockAfterAudioPlays();

  return (
    <>
      {allSoundsPlayed && (
        <Badge
          imgSrc='/badges/A-Badge.png'
          imgAlt='Accompanying Others'
          endTop={'32px'}
          endRight={'70px'}
        />
      )}
      <Title>
        <h1>Accompanying Others</h1>
      </Title>
      <p className='banner-p'>
        Defender reaches out to the student who was bullied after the bullying
        situation ends.
      </p>
      <div className='strat-wrap'>
        <FadeInBulletPointsWithAudio ulClasses={'strategy'} />

        <div className='img-wrap-strat'>
          <img
            alt='Accompanying Others'
            className='img-strat'
            src='/strategies/accompanying_others.png'
          ></img>
        </div>
      </div>
    </>
  );
}
export default Accompany;
