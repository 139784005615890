import React, { Fragment } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavbarText } from 'reactstrap';
import '../../stylesheets/main.scss';
import styles from './header.module.scss';
import { sectionEnum } from '../pages/mainprogram/mainProgramItems';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { countArray } from '../pages/mainprogram/mainProgramItems';

const Header = ({ section }) => {
  const { activity } = useParams();

  const pageswithbadgesUrls = [
    {
      img: 'S-Badge',
      style: styles.SBadge,
      url: 'stealing-the-show',
    },
    {
      img: 'T-Badge',
      style: styles.TBadge,
      url: 'turning-it-over',
    },
    {
      img: 'A-Badge',
      style: styles.ABadge,
      url: 'accompanying-others',
    },
    {
      img: 'C-Badge',
      style: styles.CBadge,
      url: 'coaching-compassion',
    },
  ];

  //find index of urls in countArray and return item and index in count array
  const badgeUrls = pageswithbadgesUrls.map((item) => {
    const index = countArray.findIndex((element) => element.url === item.url);
    return { ...item, index };
  });

  const currentIndexOfPage = countArray.findIndex(
    (item) => item.url === `${activity}`
  );

  console.log('currentIndexOfPage', currentIndexOfPage, badgeUrls);

  return (
    <Navbar className='color-nav' variant='light'>
      <div className='icon-wrapper'>
        <NavbarBrand href='/start'>
          <img
            alt='stac logo'
            className='navBarLogo '
            src={require('../../assets/images/1x/logo.png')}
          />
        </NavbarBrand>
      </div>
      <Nav className='mr-auto' navbar>
        <NavItem>
          {Object.values(sectionEnum).map((item) => (
            <NavbarText
              key={item}
              className={`breadCrumbItemActive ${
                item === section ? 'active' : 'inactiveNav'
              }`}
            >
              {item}
            </NavbarText>
          ))}
        </NavItem>
      </Nav>
      {
        //check if the current page has a badge and if it does, display it
        badgeUrls.map((item) => {
          return (
            <div className={`${styles.badgeGolder} ${item.style} `}>
              <img
                src={`/badges/${item.img}.png`}
                alt={item.img}
                className={
                  item.index < currentIndexOfPage ? styles.show : styles.grey
                }
              />
            </div>
          );
        })
      }
    </Navbar>
  );
};

export default Header;
