import React from 'react';
import Title from '../../shared/Title';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import CertificateSVG from '../../../assets/images/Certificate.svg';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

function EndSlide() {
  useUnlockAfterAudioPlays();
  return (
    <>
      <Title>
        <h1>Review of STAC Strategies</h1>
      </Title>
      <div className='card-container'>
        <div className='flex-boost'>
          <div className='make-bigger'>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>
                    Great job on completing the previous activities! Remember,
                    you can use any of the four STAC strategies to assist your
                    peers in a bullying situation.
                  </div>
                </SpeechBubble>
              </div>
              <Trainer teacher={1} />
            </div>
          </div>
          <div className='teo'>
            <FadeInBulletPointsWithAudio />
          </div>
        </div>
      </div>
    </>
  );
}

export default EndSlide;
