import React, { useEffect } from 'react';
import SpeechBubble from '../../shared/SpeechBubble.jsx';
import Trainer from '../../shared/Trainer.jsx';
import Title from '../../shared/Title.jsx';
import { useHistory, Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import Lock from '../login/lock.jsx';
import NonMainHeader from './NonMainHeader.jsx';

import { countArray as c2 } from '../mainprogram/mainProgramItems.jsx';
import { AvatarProvider, useAvatar } from '../../../hooks/AvatarContext.js';
import './start.scss';
import { autoplayEnum, useStore } from '../../../hooks/zustand.js';
import { useLocked } from '../../hooks/ContinueProvider.jsx';
import LanguageToggle from '../../shared/LanguageToggle.jsx';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  if (days > 0) {
    return (
      <span>
        {days} {days > 1 ? 'days' : 'day'}
      </span>
    );
  } else if (hours > 0) {
    return (
      <span>
        {hours} {hours > 1 ? 'hours' : 'hour'}
      </span>
    );
  } else if (minutes > 0) {
    return (
      <span>
        {minutes} {minutes > 1 ? 'mins' : 'min'}
      </span>
    );
  } else if (seconds > 0) {
    return (
      <span>
        {seconds} {seconds > 1 ? 'seconds' : 'second'}
      </span>
    );
  } else {
    return 0;
  }
};
const ChooseProgramButtonsBooster = () => {
  const { load, eject, isLoaded, language, allSoundsPlayed } = useStore(
    (state) => {
      return {
        load: state.load,
        eject: state.eject,
        isLoaded: state.audioArray.length > 0,
        allSoundsPlayed: state.allSoundsPlayed,
        language: state.language,
      };
    }
  );

  useEffect(() => {
    console.log(language, 'checke');
    eject();
    load(
      [
        {
          audio: '/audio/start/audio1.mp3',
          type: 'title',
        },
      ],
      autoplayEnum.ALL
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const { lockProgram } = useLocked();

  useEffect(() => {
    if (!isLoaded) {
      load(
        [
          {
            audio: '/audio/start/audio1.mp3',
            type: 'title',
          },
        ],
        autoplayEnum.ALL
      );
    }
  }, [isLoaded, load]);

  const isRedirecting = React.useRef(false);

  const handleMainProgramClick = () => {
    isRedirecting.current = true;
    eject();
    lockProgram();
    const nextPage = c2[0];
    load(nextPage.audio, nextPage.autoplay);
  };

  useEffect(() => {
    return () => {
      console.log(
        isRedirecting.current,
        isRedirecting.current === false,
        'here'
      );
      if (!isRedirecting.current) {
        eject();
      }
    };
  }, [eject, isRedirecting]);

  const closerDate = new Date();
  closerDate.setDate(closerDate.getDate() + 10);
  closerDate.setMinutes(closerDate.getMinutes() + 30);
  const closerTimeSTamp = closerDate.getTime();
  const furtherDate = new Date();
  furtherDate.setDate(furtherDate.getDate() + 20);
  furtherDate.setMinutes(furtherDate.getMinutes() + 30);
  const furtherTimeStamp = furtherDate.getTime();

  const { removeAvatar } = useAvatar();
  return (
    <div className='questionaireBox inStartPAge '>
      {/* <Link
        className='options intro2 startLink '
        to='/main-program/bullying-facts'
        onClick={handleMainProgramClick}
      >
        Main Program
      </Link> */}
      <Link
        className={`options intro2 startLink ${
          !allSoundsPlayed && 'startLink-disabled'
        } `}
        to='/booster/intro'
        onClick={() => {
          removeAvatar();
          eject();
        }}
      >
        Booster
      </Link>

      {/* <Link
        className='options intro2 startLink'
        to='/tracker'
        onClick={() => {
          removeAvatar();
          eject();
        }}
      >
        Tracker
      </Link>
      */}
      {/* <button className='options intro2 disabled ' disabled>
        <div className='lock-wrap'>
          <div className='lockme'>
            <Lock />
          </div>
          <span>
            <Countdown date={closerTimeSTamp} renderer={renderer} />{' '}
          </span>
        </div>
        Booster 2
      </button> */}
      {/* <button className='options intro2 disabled '>
        <div className='lock-wrap'>
          <div className='lockme'>
            <Lock />
          </div>
          <span>
            <Countdown date={furtherTimeStamp} renderer={renderer} />{' '}
          </span>
        </div>
        Tracker
      </button> */}
    </div>
  );
};

const ChooseProgramButtons = () => {
  const { load, eject, isLoaded, language, allSoundsPlayed } = useStore(
    (state) => {
      return {
        load: state.load,
        eject: state.eject,
        isLoaded: state.audioArray.length > 0,
        language: state.language,
        allSoundsPlayed: state.allSoundsPlayed,
      };
    }
  );

  useEffect(() => {
    console.log(language, 'checke');
    eject();
    load(
      [
        {
          audio: '/audio/start/audio1.mp3',
          type: 'title',
        },
      ],
      autoplayEnum.ALL
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const { lockProgram } = useLocked();

  useEffect(() => {
    if (!isLoaded) {
      load(
        [
          {
            audio: '/audio/start/audio1.mp3',
            type: 'title',
          },
        ],
        autoplayEnum.ALL
      );
    }
  }, [isLoaded, load]);

  const isRedirecting = React.useRef(false);

  const handleMainProgramClick = () => {
    isRedirecting.current = true;
    eject();
    lockProgram();
    const nextPage = c2[0];
    load(nextPage.audio, nextPage.autoplay);
  };

  useEffect(() => {
    return () => {
      console.log(
        isRedirecting.current,
        isRedirecting.current === false,
        'here'
      );
      if (!isRedirecting.current) {
        eject();
      }
    };
  }, [eject, isRedirecting]);

  const closerDate = new Date();
  closerDate.setDate(closerDate.getDate() + 10);
  closerDate.setMinutes(closerDate.getMinutes() + 30);
  const closerTimeSTamp = closerDate.getTime();
  const furtherDate = new Date();
  furtherDate.setDate(furtherDate.getDate() + 20);
  furtherDate.setMinutes(furtherDate.getMinutes() + 30);
  const furtherTimeStamp = furtherDate.getTime();

  const { removeAvatar } = useAvatar();
  return (
    <div className='questionaireBox inStartPAge '>
      <Link
        className={`options intro2 startLink ${
          !allSoundsPlayed && 'startLink-disabled'
        } `}
        to='/main-program/bullying-facts'
        onClick={handleMainProgramClick}
      >
        Main Program
      </Link>
      {/* <Link
        className='options intro2 startLink'
        to='/booster/intro'
        onClick={() => {
          removeAvatar();
          eject();
        }}
      >
        Booster 1
      </Link> */}
      {/* <Link
        className='options intro2 startLink'
        to='/tracker'
        onClick={() => {
          removeAvatar();
          eject();
        }}
      >
        Tracker
      </Link>
      */}
      {/* <button className='options intro2 disabled ' disabled>
        <div className='lock-wrap'>
          <div className='lockme'>
            <Lock />
          </div>
          <span>
            <Countdown date={closerTimeSTamp} renderer={renderer} />{' '}
          </span>
        </div>
        Booster 2
      </button> */}
      {/* <button className='options intro2 disabled '>
        <div className='lock-wrap'>
          <div className='lockme'>
            <Lock />
          </div>
          <span>
            <Countdown date={furtherTimeStamp} renderer={renderer} />{' '}
          </span>
        </div>
        Tracker
      </button> */}
    </div>
  );
};

function Start({ booster = false }) {
  return (
    <AvatarProvider>
      <div>
        <NonMainHeader />
        <div className='activityShadow'>
          <div className='activityContainer'>
            <Title>
              <h1>Welcome To STAC </h1>
            </Title>
            <div className='languageToggleContainer'>
              <LanguageToggle />
            </div>
            <div className='welcome-card  '>
              <div className='teacherContainer'>
                <div className='mainNavBubble'>
                  <SpeechBubble tween={{}}>
                    <div className='long-text'>
                      <p>Hello Everyone,</p>
                      <p> Welcome to the STAC Program!</p>
                      <p>
                        The purpose of STAC is to teach you about bullying and
                        the negative consequences for students. As a part of
                        this program, you will learn what you can do if you see
                        bullying, either at school or online. We will teach you
                        how to become a defender, so that you know what to do to
                        help your peers who are bullied.
                      </p>
                      <p>
                        We hope you enjoy completing this program and that you
                        learn how to make a positive difference at your school!
                      </p>
                    </div>
                  </SpeechBubble>
                </div>
                <Trainer teacher={3} />
              </div>
              {booster ? (
                <ChooseProgramButtonsBooster />
              ) : (
                <ChooseProgramButtons />
              )}
            </div>
          </div>
        </div>
      </div>
    </AvatarProvider>
  );
}
export default Start;
