import React from 'react';
import Title from '../../shared/Title';
import Trainer from '../../shared/Trainer';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

const STACTransition = () => {
  useUnlockAfterAudioPlays();
  return (
    <div>
      <Title>
        <h1>STAC Strategies</h1>
      </Title>
      <div className='banner'>
        <div className='wrappp'>
          <Trainer teacher={3} />
        </div>
        <div>
          <p>
            So far, you have learned about what bullying is and isn’t, the
            different types of bullying, and the role of the bystander. Now you
            are going to learn how you can help students who are targets of
            bullying. You are going to learn the four STAC strategies.
          </p>
          <p>
            You don’t have to use all four. You can use the ones that you feel
            are best suited for your personality. Also, you will learn that in
            certain cases, some strategies are better to use than others.
          </p>
        </div>
      </div>
    </div>
  );
};

export default STACTransition;
