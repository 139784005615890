import { Howl } from 'howler';
import { current } from 'immer';
import { create } from 'zustand';

export const initialState = {
  currentIndex: 0,
  isPlaying: false,
  allSoundsPlayed: true,
  endOfAudio: false,
  audioArray: [],
  autoplayOnLoad: true,
  howler: null,
  direction: null,
};

export const autoplayEnum = {
  ALL: 'ALL',
  NONE: 'NONE',
  FIRST: 'FIRST',
  AfterFirst: 'AfterFirst',
};

export const directextEnum = {
  FORWARD: 'forward',
  BACKWARD: 'backward',
};

const createHowl = (src, autoplay, onend, onplay) => {
  return new Howl({
    src: [src],
    html5: true,
    preload: true,
    autoplay: autoplay,
    onend,
    onplay,
  });
};

const store = (set) => ({
  ...initialState,
  setIndex: (index) => {
    set((store) => {
      //make sure index is in range
      if (index < 0) {
        index = 0;
      }
      if (index > store.audioArray.length - 1) {
        index = store.audioArray.length - 1;
        return {
          currentIndex: index,
          allSoundsPlayed: true,
        };
      }
      //play the audio

      return {
        currentIndex: index,
        howler: createHowl(
          store.audioArray[index].audio,
          'All',
          store.onEnd,
          store.onPlay
        ),
      };
    });
  },
  load: (audioArray, autoplay = 'ALL') => {
    console.log('audw', audioArray);
    console.log('loadz', audioArray, autoplay);
    const autoplayStatus =
      autoplay === autoplayEnum.ALL || autoplay === autoplayEnum.FIRST;
    set((store) => {
      //new
      if (store.howler) {
        console.log('unload');
        store.howler.unload();
      }

      if (store.language === 'es') {
        audioArray = audioArray.map((item) => {
          //add /es/ to the audio beigning of the  path
          return {
            ...item,
            audio: '/es' + item.audio,
          };
        });
      }

      console.log('=', audioArray);

      const howler = createHowl(
        audioArray[0].audio,
        autoplayStatus,
        store.onEnd,
        store.onPlay
      );

      return {
        audioArray: audioArray,
        allSoundsPlayed: false,
        autoplayOnLoad: autoplay,
        currentIndex: 0,
        howler,
      };
    });
  },
  play: () => {
    set((store) => {
      store.howler.play();
      return {
        isPlaying: true,
      };
    });
  },
  pause: () => {
    set((store) => {
      store.howler.pause();
      return {
        isPlaying: false,
      };
    });
  },
  next: () => {
    set((store) => {
      if (store.currentIndex === store.audioArray.length - 1) {
        return {
          allSoundsPlayed: true,
        };
      }
      return {
        currentIndex: store.currentIndex + 1,
      };
    });
  },
  back: () => {
    set((store) => {
      if (store.currentIndex === 0) {
        return;
      }
      return {
        currentIndex: store.currentIndex - 1,
      };
    });
  },
  replayAll: () => {
    set((store) => {
      const howler = createHowl(
        store.audioArray[0].audio,
        true,
        store.onEnd,
        store.onPlay
      );
      return {
        howler,
        endOfAudio: false,
        currentIndex: 0,
      };
    });
  },
  eject: () => {
    set((store) => {
      console.log('eject', store.howler);
      if (store.howler) {
        console.log('unload');
        store.howler.unload();
      }
      return {
        ...initialState,
      };
    });
  },
  onEnd: () => {
    set((store) => {
      if (store.currentIndex === store.audioArray.length - 1) {
        console.log('end of audio');
        return {
          allSoundsPlayed: true,
          endOfAudio: true,
        };
      }
      let autoplay =
        store.autoplayOnLoad === autoplayEnum.ALL ||
        store.autoplayOnLoad === autoplayEnum.AfterFirst;

      console.log('autoplay', autoplay, store.autoplayOnLoad, 'hi');
      return {
        howler: createHowl(
          store.audioArray[store.currentIndex + 1].audio,
          autoplay,
          store.onEnd,
          store.onPlay
        ),
        currentIndex: store.currentIndex + 1,
        isPlaying: autoplay,
      };
    });
  },
  onPlay: () => {
    set(() => {
      return {
        isPlaying: true,
      };
    });
  },
  setDirection: (direction) => {
    set(() => {
      return {
        direction,
      };
    });
  },
  language: window.Localize.getLanguage(),
  setLanguage: (language) => {
    window.Localize.setLanguage(language);
    set({ language: language });
  },
});

export const useStore = create(store);
