/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { TimelineMax } from 'gsap';
import Trainer from '../../../shared/Trainer';
import SpeechBubble from '../../../shared/SpeechBubble';
import Button from '../../../shared/buttons';

const ActivityModal = (props) => {
  const [response, setResponse] = useState(0);
  const inputEl = useRef(null);
  const tl = new TimelineMax();

  const submit = () => {
    let input = inputEl.current.value;
    setResponse(input);
    let correct = props.data.answer.correct;
    tl.to('.anime', 0.5, { opacity: 0 });
    tl.to('#answerGraph', 1, { opacity: 1 });
    if (input == correct) {
      // setResponse(props.data.correct);
      tl.fromTo('#correctAnswer', 2, { opacity: 0 }, { opacity: 1 }).delay(0.5);
    } else {
      tl.fromTo('#incorrectAnswer', 2, { opacity: 0 }, { opacity: 1 }).delay(
        0.5
      );
    }
  };

  const revert = () => {
    props.close();
    props.revert();
  };
  return (
    <div className='question'>
      {/* Question */}
      <div id='modalBubble' className='anime'>
        <SpeechBubble type='question-modal' tween={{ opacity: 0.0 }}>
          <p>{props.data.header.title}</p>
        </SpeechBubble>
      </div>

      {/* correct answer */}
      <div id='correctAnswer' className='responseBubble'>
        <SpeechBubble type='answer-modal' tween={{ opacity: 0.0 }}>
          <div className='heightScale'>
            {props.data.correct.title}
            <br />
            {props.data.correct.content.basic}
          </div>
        </SpeechBubble>
      </div>
      {/* incorrect answer */}
      <div id='incorrectAnswer' className='responseBubble'>
        <SpeechBubble type='answer-modal' tween={{ opacity: 0.0 }}>
          <div className='incorrectText'>
            {props.data.incorrect.title}
            <br />
            You entered {response}/10, <br />
            {props.data.correct.content.basic}
          </div>
        </SpeechBubble>
      </div>

      <div id='descriptionBox' className='anime chalkboard'>
        {props.data.header.description}
      </div>

      <Trainer />

      <div className='inputBox'>
        <input
          ref={inputEl}
          id='input'
          className='percentageInput'
          type='text'
        />{' '}
        / 10
      </div>
      <div className='submitButton'>
        <Button
          click={() => submit('answer')}
          type='activitySubmit'
          text='Submit'
        />
      </div>
      <Button
        styleName='activityModalClose'
        text='X'
        type='options+'
        click={revert}
      />
    </div>
  );
};

export default ActivityModal;
