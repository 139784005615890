import React from 'react';

function ButtonBox(props) {
  return (
    <>
      <div className='flex-column practice-time'>{props.children}</div>
    </>
  );
}

export default ButtonBox;
