import React from 'react';

function Explanation(props) {
  return (
    <>
      <div className='explanation'>
        <p className='explanation'>{props.children}</p>
        {props.image ? props.image : null}
      </div>
    </>
  );
}

export default Explanation;
