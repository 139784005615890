import React, { useState } from 'react';
import downArrow from './downArrow.svg';
import upArrow from './upArrow.svg';
import { useAutoAnimate } from '@formkit/auto-animate/react';

function Container(props) {
  const [parentRef] = useAutoAnimate();
  const correctOrder = [3, 1, 4, 2];
  const [cards, setCards] = useState(
    props.initialCardState.map((card) => ({ ...card, correct: false }))
  );

  const submit = (answer) => {
    props.submit(answer);
  };

  const swapArrayElements = (arr, indexA, indexB) => {
    const temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };
  // Move an item up by index
  function moveItemUp(oldIndex) {
    //find new index
    if (oldIndex > 0) {
      let newIndex = oldIndex - 1;
      for (newIndex; newIndex >= 0; newIndex--) {
        if (cards[newIndex].correct) {
          continue;
        } else {
          const sortedArray = swapArrayElements([...cards], oldIndex, newIndex);
          const sortedAndCheckedIfCorrect = sortedArray.map((item, index) => {
            return { ...item, correct: item.id === correctOrder[index] };
          });
          setCards(sortedAndCheckedIfCorrect);
          break;
        }
      }
    }
  }

  function moveItemDown(oldIndex) {
    //find new index
    if (oldIndex < cards.length - 1) {
      let newIndex = oldIndex + 1;
      for (newIndex; newIndex < cards.length; newIndex++) {
        if (cards[newIndex].correct) {
          continue;
        } else {
          const sortedArray = swapArrayElements([...cards], oldIndex, newIndex);
          const sortedAndCheckedIfCorrect = sortedArray.map((item, index) => {
            return { ...item, correct: item.id === correctOrder[index] };
          });
          setCards(sortedAndCheckedIfCorrect);
          break;
        }
      }
    }
  }

  const allCorrect = cards.every((item, index) => item.correct === true);

  return (
    <>
      <ul id={props.id} className='cardContianer' ref={parentRef}>
        {cards.map((card, i) => (
          <li
            className={`movableCard ${
              card.correct ? 'correctCard' : 'inCorrectCard'
            } `}
            key={card.id}
          >
            <span>{card.text}</span>
            {
              // only show the drag and drop buttons if the card is not correct
              !card.correct && !props.isPlaying && (
                <div className='dragIconWrap'>
                  <button onClick={() => moveItemUp(i)}>
                    <img src={upArrow} alt='drag and drop component' />
                  </button>
                  <button onClick={() => moveItemDown(i)}>
                    <img src={downArrow} alt='drag and drop component' />
                  </button>
                </div>
              )
            }
          </li>
        ))}
      </ul>
      <div>
        <button
          disabled={!allCorrect}
          onClick={() => submit(cards)}
          className='activitySubmit'
          id='rankedSubmitButton'
        >
          Next
        </button>
      </div>
    </>
  );
}
export default Container;
