import React from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useLocked } from '../../hooks/ContinueProvider';
import { useStore } from '../../../hooks/zustand';
import { useEffect } from 'react';

function Students() {
  const { unlockProgram } = useLocked();
  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  useEffect(() => {
    if (allSoundsPlayed) {
      unlockProgram();
    }
  }, [allSoundsPlayed, unlockProgram]);

  return (
    <>
      <Title>
        <h1>Students Who Bully</h1>
      </Title>
      <div className='bullet-img-wrap'>
        <FadeInBulletPointsWithAudio />
        <div className='img-wrap-portait'>
          <img alt='bullying' src='/studentsWhoBully.png' />
        </div>
      </div>
    </>
  );
}

export default Students;
