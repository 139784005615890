import React from 'react';
import Crumb from './breadCrumbs.jsx';

function CrumbBox({ currentPage, lastPage }) {
  return (
    <div className='crumbBox'>
      {Array.from({ length: lastPage }).map((_, index) =>
        currentPage > index ? (
          <Crumb key={index} />
        ) : (
          <Crumb key={index} on={true} />
        )
      )}
    </div>
  );
}

export default CrumbBox;
