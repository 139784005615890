import React from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';
import Badge from './Badge';

function Steal() {
  const allSoundsPlayed = useUnlockAfterAudioPlays();

  return (
    <>
      {allSoundsPlayed && (
        <Badge
          imgSrc='/badges/S-Badge.png'
          imgAlt='Stealing The Show'
          endTop={'32px'}
          endRight={'190px'}
        />
      )}
      <Title>
        <h1>Stealing The Show</h1>
      </Title>
      <p className='banner-p'>
        Defenders use distraction or humor to interrupt a bullying situation and
        turn their peers’ attention away from bullying.
      </p>
      <div className='strat-wrap'>
        <FadeInBulletPointsWithAudio ulClasses='strategy' />
        <div className='img-wrap-strat'>
          <img
            alt='Stealing The Show'
            className='img-strat'
            src='/strategies/stealing_the_show.png'
          ></img>
        </div>
      </div>
    </>
  );
}
export default Steal;
