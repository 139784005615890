import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

const AvatarContext = createContext();

export const useAvatar = () => {
  return useContext(AvatarContext);
};

export const AvatarProvider = ({ children }) => {
  const { getStorageItem, setStorageItem } = useLocalStorage();
  const [selectedAvatar, setAvatar] = useState(() => {
    const avatar = getStorageItem('avatar');
    if (!avatar) return;
    return avatar;
  });

  const saveAvatar = (avatar) => {
    setAvatar(avatar);
    setStorageItem('avatar', avatar);
  };

  const removeAvatar = () => {
    setAvatar(null);
    setStorageItem('avatar', null);
  };

  return (
    <AvatarContext.Provider
      value={{
        selectedAvatar,
        saveAvatar,
        removeAvatar,
      }}
    >
      {children}
    </AvatarContext.Provider>
  );
};
