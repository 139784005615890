import React from 'react';

const ReplayButton = () => {
  return (
    <svg
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 925.52 925.7'
    >
      <defs></defs>
      <g id='Layer_1-2' data-name='Layer 1'>
        <g>
          <path
            className='cls-1'
            d='m925.52,462.93c0,255.45-207.07,462.78-462.74,462.78S0,718.38,0,462.93,207.1,0,462.78,0s462.74,207.25,462.74,462.93Z'
          />
          <path
            fill='#fff'
            className='cls-2'
            d='m470.48,280v-75.99l-132.08,110.41,132.08,110.48v-71.79c77.83,3.91,140,68.76,140,147.48s-66.33,147.92-147.7,147.92-148.1-66.33-148.1-147.92h-72.74c0,121.9,98.94,221.03,220.85,221.03s220.81-99.13,220.81-221.03-94.78-216.46-213.11-220.59Z'
          />
        </g>
      </g>
    </svg>
  );
};

export default ReplayButton;
