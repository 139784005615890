import React from 'react';

const PauseButton = () => {
  return (
    <svg
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 925.7 925.7'
    >
      <defs>
        <style></style>
      </defs>
      <g id='Layer_1-2' data-name='Layer 1'>
        <g>
          <path
            className='cls-1'
            d='m925.7,462.93c0,255.45-207.07,462.78-462.74,462.78S0,718.38,0,462.93,207.25,0,462.96,0s462.74,207.25,462.74,462.93Z'
          />
          <rect
            fill='#fff'
            className='cls-2'
            x='315.59'
            y='247.71'
            width='73.3'
            height='430.2'
          />
          <rect
            fill='#fff'
            className='cls-2'
            x='537'
            y='247.71'
            width='73.3'
            height='430.2'
          />
        </g>
      </g>
    </svg>
  );
};

export default PauseButton;
