import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../../shared/Footer';
import { Wrapper } from './Booster';
import Tracker from './Tracker';
import React from 'react';

function SoloTracker() {
  const history = useHistory();

  const goBackToStart = () => {
    history.push('/start');
  };

  return (
    <Wrapper>
      <div className='mainSection'>
        <div className='activityShadow'>
          <div className='activityContainer'>
            <Tracker />
          </div>
        </div>
      </div>
      <Footer
        totalPageNumber={null}
        handleBack={goBackToStart}
        handleNext={goBackToStart}
      />
    </Wrapper>
  );
}

export default SoloTracker;
