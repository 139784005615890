import React, { useEffect } from 'react';
import Questionaire from '../../shared/Questionaire.jsx';
import Trainer from '../../shared/Trainer.jsx';
import SpeechBubble from '../../shared/SpeechBubble';
import Title from '../../shared/Title.jsx';
import { gsap } from 'gsap';
import { useLocked } from '../../hooks/ContinueProvider.jsx';
import { useStore } from '../../../hooks/zustand.js';

function BullyFacts2(props) {
  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  const { unlockProgram } = useLocked();
  useEffect(() => {
    // props.nextToggle();

    gsap.from('mainNavBubble', {
      left: '1000px',
    });
  }, [props.data.answer1]);

  gsap.from('.BullyFacts2Box', {
    bottom: '-100em',
    duration: 1,
  });
  return (
    <>
      <Title>
        <h1>More Bullying Facts</h1>
      </Title>
      <div className='card-container'>
        <div>
          <div className='teacherContainer'>
            <div className='mainNavBubble'>
              <SpeechBubble tween={{}}>
                <div>
                  True or False? Most students have negative attitudes about
                  peers who report bullying to teachers.
                </div>
              </SpeechBubble>
            </div>
            <Trainer />
          </div>
        </div>
        <div className='intro2Box'>
          <div className='questionaireBox '>
            {props.data.map((item, index) => (
              <Questionaire
                key={index}
                onClick={item.correct ? unlockProgram : null}
                modalText={item}
                styleName='intro2'
                buttonType='options+'
                buttonText={item.button}
                audio={item.audio}
                disabled={!allSoundsPlayed}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default BullyFacts2;
