import { Navbar } from 'reactstrap';
import React from 'react';

const NonMainHeader = () => {
  return (
    <Navbar className='color-nav' variant='light'>
      <div className='booster-head-wrap'>
        <img alt='logo' className='boost-logo ' src={'/logoBig.png'} />
      </div>
    </Navbar>
  );
};

export default NonMainHeader;
