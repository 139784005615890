import { createContext, useContext, useMemo } from 'react';
import React from 'react';
import { Howl } from 'howler';
import { useImmer } from 'use-immer';
import useSoundControls from './useSoundControls';

const AudioContext = createContext();

export const useAudio = () => {
  return useContext(AudioContext);
};

const AudioProvider = ({ children }) => {
  const audio = useSoundControls();
  return (
    <AudioContext.Provider value={audio}>{children}</AudioContext.Provider>
  );
};
export default AudioProvider;
