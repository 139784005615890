/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import Questionaire from '../../shared/Questionaire.jsx';
import data from '../../../assets/json/modalResponses.json';
import '../../../stylesheets/main.scss';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer.jsx';
import Title from '../../shared/Title.jsx';
import { gsap } from 'gsap';
import { useLocked } from '../../hooks/ContinueProvider.jsx';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays.js';
import { useStore } from '../../../hooks/zustand.js';
import LanguageToggle from '../../shared/LanguageToggle.jsx';

function Intro2(props) {
  const { unlockProgram } = useLocked();

  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  console.log(allSoundsPlayed, 'all');

  useEffect(() => {
    gsap.from('.intro2Box', {
      bottom: '-100em',
      duration: 0.5,
    });
    gsap.from('.mainNavBubble', {
      left: '1000px',
    });
  }, []);

  return (
    <>
      <Title>
        <h1>Bullying Facts</h1>
      </Title>
      <div className='card-container'>
        <div>
          <div className='teacherContainer'>
            <div className='mainNavBubble'>
              <SpeechBubble tween={{}}>
                <div>
                  What percent of middle school students report being bullied at
                  school? Choose an answer and click next.
                </div>
              </SpeechBubble>
            </div>
            <Trainer teacher={2} />
          </div>
        </div>
        <div className='intro2Box'>
          <div className='questionaireBox '>
            {/* <button onClick = {setState(true)}></button> */}
            <Questionaire
              modalText={data.intro2.answer1}
              styleName='intro2'
              buttonType='options+'
              buttonText='1 out of 10 '
              audio={'/audio/bullying-facts/1outa10.mp3'}
              disabled={!allSoundsPlayed}
            />
            <Questionaire
              onClick={() => unlockProgram()}
              styleName='intro2'
              modalText={data.intro2.answer2}
              buttonType='options+'
              buttonText='3 out of 10'
              audio={'/audio/bullying-facts/3outa10.mp3'}
              disabled={!allSoundsPlayed}
            />
            <Questionaire
              modalText={data.intro2.answer3}
              styleName='intro2'
              buttonType='options+'
              buttonText='5 out of 10'
              audio={'/audio/bullying-facts/5outa10.mp3'}
              disabled={!allSoundsPlayed}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Intro2;
