import React from 'react';
import { useImmer } from 'use-immer';
import { useMemo } from 'react';
import { Howl } from 'howler';

const initialState = {
  isPlaying: false,
  index: 0,
  allSoundsPlayed: false,
  audioArray: [],
  howler: null,
  autoplay: true,
};

const useSoundControls = (audioFiles, startOnLoad = true) => {
  const [
    { isPlaying, index, audioArray, howler, allSoundsPlayed, autoplay },
    setAudio,
  ] = useImmer(initialState);

  const actions = useMemo(() => {
    const createPlayer = (file, autoplay) => {
      const sound = new Howl({
        src: [file],
        autoplay: autoplay,
        controls: true,
        onplay: () => {
          setAudio((draft) => {
            draft.isPlaying = true;
          });
        },
        onend: () => {
          setAudio((draft) => {
            if (draft.index === draft.audioArray.length - 1) {
              draft.allSoundsPlayed = true;
              return;
            }
            draft.index = draft.index + 1;
            draft.howler = createPlayer(
              draft.audioArray[draft.index],
              draft.autoplay
            );
          });
        },
        onloaderror: function (id, error) {
          console.log('Load Error: ' + error);
        },
      });
      return sound;
    };

    const isInitialized = () => {
      const loaded = audioArray.length > 0;
      if (!loaded) {
        throw new Error('audio not loaded, call load() first');
      }
      return loaded;
    };

    return {
      load: (arrOfAudioFiles, autoplay = true) => {
        if (howler) {
          howler.unload();
        }
        const sound = createPlayer(arrOfAudioFiles[0], autoplay);
        setAudio((draft) => {
          draft.audioArray = arrOfAudioFiles;
          draft.howler = sound;
          draft.autoplay = autoplay;
        });
      },
      play: () => {
        if (!isInitialized()) return;
        setAudio((draft) => {
          draft.isPlaying = true;
        });
        howler.play();
      },
      replayAll: () => {
        if (!isInitialized()) return;
        howler.unload();
        const sound = createPlayer(audioArray[0], autoplay);
        setAudio(() => ({
          ...initialState,
          howler: sound,
          audioArray: audioArray,
          isPlaying: true,
        }));
      },
      eject: () => {
        if (!isInitialized()) return;
        howler.unload();
        setAudio(() => ({
          ...initialState,
        }));
      },
      next: () => {
        if (!isInitialized()) return;
        howler.unload();
        setAudio((draft) => {
          if (draft.index === draft.audioArray.length - 1) {
            draft.allSoundsPlayed = true;
            return;
          }
          draft.index = draft.index + 1;
          draft.howler = createPlayer(draft.audioArray[draft.index], true);
        });
      },
      back: () => {
        if (!isInitialized()) return;
        howler.unload();
        setAudio((draft) => {
          if (draft.index === 0) {
            return;
          }
          draft.index = draft.index - 1;
          draft.howler = createPlayer(draft.audioArray[draft.index], true);
        });
      },
      pause: () => {
        if (!isInitialized()) return;
        setAudio((draft) => {
          draft.isPlaying = false;
        });
        howler.pause();
      },
    };
  }, [audioArray, autoplay, howler, setAudio]);

  if (audioFiles) {
    actions.load(audioFiles, startOnLoad);
  }

  return {
    controls: {
      ...actions,
    },
    status: {
      isPlaying,
      index,
      allSoundsPlayed,
      isLoaded: audioArray.length > 0,
    },
  };
};

export default useSoundControls;
