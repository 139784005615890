import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import Title from '../../shared/Title';
import styles from './congrats.module.scss';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';

export const Congrats = () => {
  const imageRefs = useRef([]);

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1 });

    tl.to(imageRefs.current[0], { scale: 1.5, duration: 0.5 })
      .to(imageRefs.current[0], { scale: 1, duration: 0.5 })
      .to(imageRefs.current[1], { scale: 1.5, duration: 0.5 }, '-=0.5')
      .to(imageRefs.current[1], { scale: 1, duration: 0.5 })
      .to(imageRefs.current[2], { scale: 1.5, duration: 0.5 }, '-=0.5')
      .to(imageRefs.current[2], { scale: 1, duration: 0.5 })
      .to(imageRefs.current[3], { scale: 1.5, duration: 0.5 }, '-=0.5')
      .to(imageRefs.current[3], { scale: 1, duration: 0.5 });
  }, []);

  return (
    <>
      <Title>
        <h1>Congratulations!</h1>
      </Title>
      <div className={styles.flex}>
        <div className='teacherContainer'>
          <div className='mainNavBubble'>
            <SpeechBubble tween={{}}>
              <div>Great job completing the STAC Program! </div>
            </SpeechBubble>
          </div>
          <Trainer teacher={4} />
        </div>
        <div className={styles.stacContainer}>
          <img
            ref={(el) => (imageRefs.current[0] = el)}
            src='/badges/S-Badge.png'
            alt='Loading Dot 1'
            className={styles.stacImg}
          />
          <img
            ref={(el) => (imageRefs.current[1] = el)}
            src='/badges/T-Badge.png'
            alt='Loading Dot 2'
            className={styles.stacImg}
          />
          <img
            ref={(el) => (imageRefs.current[2] = el)}
            src='/badges/A-Badge.png'
            alt='Loading Dot 3'
            className={styles.stacImg}
          />
          <img
            ref={(el) => (imageRefs.current[3] = el)}
            src='/badges/C-Badge.png'
            alt='Loading Dot 3'
            className={styles.stacImg}
          />
        </div>
      </div>
    </>
  );
};
