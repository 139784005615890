import React, { useEffect } from 'react';
import gsap from 'gsap';
import { createPortal } from 'react-dom';
import styles from './badge.module.scss';

//make portal for badge

export function Badge({ imgSrc, imgAlt, endTop, endRight }) {
  const badgeRef = React.useRef(null);
  const screenSize = window.innerWidth;

  console.log(screenSize);

  useEffect(() => {
    const badge = badgeRef.current;

    // Initial CSS properties for the badge
    gsap.set(badge, { width: '0px' });

    // Create a GSAP timeline
    const tl = gsap.timeline();

    const screenSize = window.innerWidth;

    console.log(screenSize);

    // Add animation to scale up and pulse for 3 seconds
    tl.to(badge, {
      delay: 0,
      duration: 2,
      width: '500px',
      ease: 'power1.inOut',
    });

    // Add pulse animation
    tl.to(badge, {
      duration: 0.35,
      width: '450px',
      repeat: 4,
      yoyo: true,
      ease: 'power1.inOut',
      stagger: 0.1, // slight delay between each pulse
    });

    // Add animation to shrink and move to the top-right corner

    if (screenSize > 650) {
      tl.to(badge, {
        duration: 1,
        width: '50px',
        rotation: -720,
        top: endTop,
        transform: 'translate(-50%, -50%)',
        right: endRight,
        ease: 'power1.inOut',
      });
    } else {
      // just fade it out
      tl.to(badge, {
        duration: 1,
        opacity: 0,
        ease: 'power1.inOut',
      });
    }
  }, [endRight, endTop]);

  return (
    <>
      {createPortal(
        <img
          ref={badgeRef}
          className={styles.badgeCenter}
          src={imgSrc}
          alt={imgAlt}
        />,
        document.body
      )}
    </>
  );
}

export default Badge;
