import { useLocked } from '../components/hooks/ContinueProvider';
import { useStore } from './zustand';
import { useEffect } from 'react';

export const useUnlockAfterAudioPlays = () => {
  const { unlockProgram } = useLocked();

  const { allSoundsPlayed } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  useEffect(() => {
    if (allSoundsPlayed) {
      unlockProgram();
    }
  }, [allSoundsPlayed, unlockProgram]);

  return allSoundsPlayed;
};
