import React, { useEffect, useState } from 'react';
import Title from '../../shared/Title';
import Trainer from '../../shared/Trainer';
import Button from '../../shared/buttons';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { useLocked } from '../../hooks/ContinueProvider';
import { useStore } from '../../../hooks/zustand';
import styles from './Tracker.module.scss';

const trackerQuestions = {
  q1: {
    question:
      'Have you seen bullying behaviors since the last time we met and you learned about the STAC strategies?',
    audio: '/booster/audio/tracker/2.mp3',
    answers: [
      {
        answer: 'Yes',
        next: {
          question: 'q2',
          message: null,
        },
      },
      {
        answer: 'No',
        next: {
          question: null,
          message: 'm1',
        },
      },
    ],
    type: 'radio',
    id: uuidv4(),
  },
  q2: {
    question: 'What type of bullying behaviors have you seen?',
    type: 'checkbox',
    audio: '/booster/audio/tracker/3.mp3',
    id: uuidv4(),
    answers: [
      {
        answer:
          'Physical bullying – for example, hurting someone’s body or destroying someone’s belongings',
        next: {
          question: 'q3',
          message: null,
        },
      },
      {
        answer:
          'Verbal bullying – for example, saying or writing mean things about someone',
        next: {
          question: 'q3',
          message: null,
        },
      },
      {
        answer:
          'Relationship bullying – for example, spreading rumors or leaving someone out on purpose',
        next: {
          question: 'q3',
          message: null,
        },
      },
      {
        answer:
          'Cyberbullying – for example, posting embarrassing pictures or videos of someone',
        next: {
          question: 'q3',
          message: null,
        },
      },
      {
        answer: 'I am not sure what type of bullying I saw',
        next: {
          question: 'q3',
          message: null,
        },
        canBeOnlyAnswer: true,
      },
    ],
  },
  q3: {
    question: 'Which STAC Strategies did you use? Pick all the apply:',
    audio: '/booster/audio/tracker/4.mp3',
    answers: [
      {
        answer:
          'Stealing the Show – using humor or distraction to stop a bullying situation.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer:
          'Turning it Over – reporting a bullying situation to an adult at school who you trust.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer:
          'Accompanying Others – being a friend to a peer who was bullied.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer:
          'Coaching Compassion – telling your peer who bullied someone to stop. Remember to only use Coaching Compassion if you are older, good friends with the bully or think the bully will listen to you and respect you.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer: 'I did not use any of the STAC strategies.',
        next: {
          question: 'q4',
          message: null,
        },
        canBeOnlyAnswer: true,
      },
    ],
    type: 'checkbox',
    id: uuidv4(),
  },
  q4: {
    question:
      'If you did not use a STAC Strategy, which ones could you use the next time you see a bullying situation? Pick all the apply:',
    audio: '/booster/audio/tracker/7.mp3',
    answers: [
      {
        answer:
          'Stealing the Show – using humor or distraction to stop a bullying situation.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer:
          'Turning it Over – reporting a bullying situation to an adult at school who you trust.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer:
          'Accompanying Others – being a friend to a peer who was bullied.',
        next: {
          question: null,
          message: 'm2',
        },
      },
      {
        answer:
          'Coaching Compassion – telling your peer who bullied someone to stop. Remember to only use Coaching Compassion if you are older, good friends with the bully or think the bully will listen to you and respect you.',
        next: {
          question: null,
          message: 'm2',
        },
      },
    ],
    type: 'checkbox',
    id: uuidv4(),
  },
};

Object.keys(trackerQuestions).forEach((key) => {
  trackerQuestions[key].answers.forEach((answer) => {
    answer.selected = false;
  });
});

const trackerMessages = {
  m1: 'Thank you for answering. Keep an eye out for bullying so that you have a chance to use a STAC strategy and make a positive difference at school. Also, if you see bullying and do not feel comfortable using a strategy, you can think about which strategy you could use next time you get opportunity to intervene. ',
  m2: 'Great job! Keep using the STAC Strategies! The more you use them, the more comfortable you will become with them and you will make a positive different for your peers at school.',
};

const Tracker = () => {
  return (
    <div>
      <Title>
        <h1>Tracker</h1>
      </Title>
      <TrackerQuiz />
    </div>
  );
};

const TrackerQuiz = () => {
  const startingPoint = 'q1';
  const [quiz, setQuiz] = useImmer(trackerQuestions);
  const [currentQuestionKey, setCurrentQuestionKey] = useImmer(startingPoint);
  const [quizOrder, setQuizOrder] = useState([startingPoint]);
  const [message, setMessage] = useState(null);
  const [started, setStarted] = useState(false);

  const { allSoundsPlayed, load, isPlaying, hasAudio, eject } = useStore(
    (state) => {
      return {
        allSoundsPlayed: state.allSoundsPlayed,
        load: state.load,
        isPlaying: state.isPlaying,
        hasAudio: state.audioArray.length > 0,
        eject: state.eject,
      };
    }
  );

  const {
    question,
    answers,
    type: questionType,
    id: questionId,
  } = quiz[currentQuestionKey];

  const handleBack = () => {
    eject();
    if (currentQuestionKey === 'q1') {
      setStarted(false);
    } else {
      if (quiz[currentQuestionKey]?.audio) {
        load([
          {
            audio: quiz[currentQuestionKey].audio,
          },
        ]);
      }
    }
    if (quizOrder.length > 1) {
      setQuizOrder((draft) => draft.slice(0, -1));
      setCurrentQuestionKey(quizOrder[quizOrder.length - 2]);
    }
  };

  const { unlockProgram } = useLocked();

  const answeredQuestion = answers.find((answer) => answer.selected);

  useEffect(() => {
    if (message && allSoundsPlayed) {
      unlockProgram();
    }
  }, [allSoundsPlayed, message, unlockProgram]);

  const handleNext = () => {
    eject();
    if (!started) {
      setStarted(true);
      load([
        {
          audio: quiz[currentQuestionKey].audio,
        },
      ]);
    }
    //if has audio, play audio

    if (answeredQuestion) {
      if (answeredQuestion.next.message) {
        setMessage(trackerMessages[answeredQuestion.next.message]);
        if (answeredQuestion.next.message === 'm2') {
          load([
            {
              audio: '/booster/audio/tracker/5.mp3',
            },
          ]);
        }
        if (answeredQuestion.next.message === 'm1') {
          load([
            {
              audio: '/booster/audio/tracker/6.mp3',
            },
          ]);
        }
      } else {
        if (answeredQuestion.next.question) {
          setCurrentQuestionKey(answeredQuestion.next.question);
          console.log('next question', answeredQuestion.next.question);
          if (quiz[answeredQuestion.next.question]?.audio) {
            load([
              {
                audio: quiz[answeredQuestion.next.question].audio,
              },
            ]);
          }
          setQuizOrder((draft) => [...draft, answeredQuestion.next.question]);
        }
      }
    }
  };

  const handleInputChange = (
    myAnswer,
    currentlySelected,
    type,
    canBeOnlyAnswer
  ) => {
    if (type === 'radio' || canBeOnlyAnswer) {
      //if radio, unselect all other answers. Or if it has the canBeOnlyAnswer property, unselect all other answers before selecting the current one
      setQuiz((draft) => {
        draft[currentQuestionKey].answers.forEach((answer) => {
          answer.selected = false;
        });
        if (!currentlySelected) {
          draft[currentQuestionKey].answers.find(
            (a) => a.answer === myAnswer
          ).selected = true;
        }
      });
    } else {
      setQuiz((draft) => {
        //if there is a box checked that can only be the only answer, unselect it. Then select the current answer or toggle it off
        const unselectMe = draft[currentQuestionKey].answers.find(
          (a) => a.canBeOnlyAnswer
        );
        if (unselectMe) {
          unselectMe.selected = false;
        }
        draft[currentQuestionKey].answers.find(
          (a) => a.answer === myAnswer
        ).selected = !draft[currentQuestionKey].answers.find(
          (a) => a.answer === myAnswer
        ).selected;
      });
    }
  };

  const shouldEnableButton = () => {
    console.log(
      'should enable button',
      started,
      quiz[currentQuestionKey].answers.some((answer) => answer.selected),
      quiz[currentQuestionKey].answers,
      hasAudio && allSoundsPlayed
    );
    if (!started) {
      if (allSoundsPlayed) {
        return true;
      } else {
        return false;
      }
    }
    //if item not answered or audio is playing, disable button
    if (quiz[currentQuestionKey].answers.some((answer) => answer.selected)) {
      if (!(hasAudio && allSoundsPlayed)) {
        return false;
      }
      return true;
    } else {
      return false;
    }
    return true;
  };

  console.log(quiz[currentQuestionKey], 'current question');

  return (
    <div className='tracker'>
      <div className='quiz'>
        {message ? (
          <div className='messages'>
            <p>{message}</p>{' '}
          </div>
        ) : (
          <>
            {started ? (
              <div className='questionxs'>
                <p>{question}</p>
                <div className='options-wrapper'>
                  {answers.map((answer) => (
                    <div
                      className='trackerAnswer'
                      key={questionId + answer.answer}
                    >
                      <input
                        type={questionType}
                        id={questionId + answer.answer}
                        name={
                          questionType === 'radio'
                            ? questionId
                            : questionId + answer
                        }
                        value={answer.answer}
                        checked={answer.selected}
                        onClick={() =>
                          handleInputChange(
                            answer.answer,
                            answer.selected,
                            questionType,
                            answer.canBeOnlyAnswer
                          )
                        }
                      />
                      <label for={answer.answer}>{answer.answer}</label>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className='questionxs'>
                <div className='banner'>
                  <div className='wrappp'>
                    <Trainer teacher={4} />
                  </div>
                  <p>
                    Now complete the tracker by answering questions about what
                    you have seen and strategies you have used since the last
                    time you completed this online program. No one will see your
                    answers.
                  </p>
                </div>
              </div>
            )}

            <div
              className={`tracker-button-wrap ${
                !started ? 'single' : 'double'
              } `}
            >
              {/* {started && (
                <Button type='navigationLeft' text='BACK' click={handleBack} />
              )} */}
              <div className={styles.nextWrapper}>
                <button
                  type='button'
                  onClick={handleNext}
                  disabled={!shouldEnableButton()}
                >
                  Next
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Tracker;
