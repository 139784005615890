import React from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPoints } from './FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from './FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

function Intro4() {
  useUnlockAfterAudioPlays();
  return (
    <>
      <Title>
        <h1>What Isn’t Bullying?</h1>
      </Title>
      <div className='bullet-img-wrap'>
        <FadeInBulletPointsWithAudio />
        <div className='img-wrap'>
          <img alt='bullying' src='/whatIsntBullying.png' />
        </div>
      </div>
    </>
  );
}
export default Intro4;
