import React, { useReducer, useState } from 'react';
import styles from './ActivityModule.module.scss';
import PersonSVG from './PersonSVG';
import { ReactComponent as PersonA } from './People/PersonA.svg';
import { ReactComponent as PersonB } from './People/PersonB.svg';
import { ReactComponent as PersonC } from './People/PersonC.svg';
import { ReactComponent as PersonD } from './People/PersonD.svg';
import { ReactComponent as PersonE } from './People/PersonE.svg';
import { ReactComponent as PersonF } from './People/PersonF.svg';
import { ReactComponent as PersonG } from './People/PersonG.svg';
import { ReactComponent as PersonH } from './People/PersonH.svg';
import { ReactComponent as PersonI } from './People/PersonI.svg';
import { ReactComponent as PersonJ } from './People/PersonJ.svg';
import { useStore } from '../../../../hooks/zustand';
import PersonTen from './People/PersonTen';
import { v4 as uuidv4 } from 'uuid';
import { Bar } from 'react-chartjs-2';
import Title from '../../../shared/Title';
import { styled } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useLocked } from '../../../hooks/ContinueProvider';
const correctAnswers = [
  {
    name: 'outsiders',
    correctAnswer: 4,
    userAnswer: 0,
  },
  {
    name: 'assistants',
    correctAnswer: 2,
    userAnswer: 0,
  },
  {
    name: 'reinforcers',
    correctAnswer: 2,
    userAnswer: 0,
  },
  {
    name: 'defenders',
    correctAnswer: 2,
    userAnswer: 0,
  },
];

const ActivityModule = () => {
  const { language } = useStore((state) => {
    return {
      language: state.language,
    };
  });
  const [submit, setSubmit] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const { unlockProgram } = useLocked();
  const [bystanders, setBystanders] = useState(
    correctAnswers
      .map((item) =>
        [...Array(item.correctAnswer).fill(0)].map((person) => ({
          id: uuidv4(),
          role: null,
        }))
      )
      .flat()
  );

  const getAnswerTotals = () => {
    return correctAnswers.map((correctAnswerItem) => {
      let timesSelected = 0;
      bystanders.forEach((bystanderItem) => {
        if (correctAnswerItem.name === bystanderItem.role) {
          timesSelected++;
        }
      });
      return { ...correctAnswerItem, userAnswer: timesSelected };
    });
  };

  const isPerfect = () => {
    return getAnswerTotals().every(
      (item) => item.correctAnswer === item.userAnswer
    );
  };

  const addRole = (id) => {
    if (selectedRole) {
      setBystanders((prevBystanders) =>
        prevBystanders.map((item) =>
          item.id === id ? { ...item, role: selectedRole } : item
        )
      );
    }
  };

  const labels =
    language === 'es'
      ? ['Forasteros', 'Asistentes', 'Reforzadores', 'Defensores']
      : ['Outsiders', 'Assistants', 'Reinforcers', 'Defenders'];

  return (
    <div>
      <Title>
        <h1>Take Your Best Guess</h1>
      </Title>
      <section>
        <div>
          <p className={'prompt'}>
            <b>Instructions:</b> Guess how many assistants, reinforcers,
            outsiders, and defenders there are in a group of 10 students. Click
            the bystander role and then highlight the number of students you
            think represents each bystander role.
          </p>
        </div>
        <div className={styles.activityWrap}>
          <div
            className={`${styles.outerWrap} ${
              !submit && selectedRole && styles[selectedRole + 'Cursor']
            }`}
          >
            {submit ? (
              <div className={styles.doubleWide}>
                <div className={styles.oscrollG}>
                  <div className='chartContainer'>
                    <Bar
                      plugins={[ChartDataLabels]}
                      options={{
                        plugins: {
                          tooltip: {
                            enabled: false,
                          },
                          datalabels: {
                            display: true, // Enable data labels
                            color: 'white', // Font color for data labels
                            anchor: 'center', // Position of data labels (start, center, end)
                            align: 'end', // Text alignment (start, center, end)
                            textStrokeColor: 'white',
                            font: {
                              size: '20',
                              weight: 'bold',
                            },
                            formatter: (value, context) => {
                              return `${value}/10`; // Display the value as a percentage
                            },
                          },
                          legend: {
                            display: false,
                          },
                        },
                        backgroundColor: [
                          '#0352a0',
                          'rgb(239, 51, 64)',
                          'rgba(255, 159, 64)',
                          'rgba(153, 102, 255',
                        ],
                        scales: {
                          y: {
                            display: false,
                            // beginAtZero: true,
                            // ticks: {
                            //   color: 'black',
                            // },
                          },
                          x: {
                            ticks: {
                              color: 'black',
                              font: {
                                size: 14,
                                weight: 'bold',
                              },
                            },
                          },
                        },
                      }}
                      data={{
                        labels,
                        datasets: [
                          {
                            label: 'Bystander role out of 10',
                            data: [4, 2, 2, 2],
                            borderWidth: 2,
                          },
                        ],
                      }}
                    />
                  </div>
                </div>
                <div className={styles.whiteBackground}>
                  <h2 className={styles.subHeader}>
                    {isPerfect()
                      ? 'Great Job! You got them all right!'
                      : 'Nice Try!'}
                  </h2>
                  <ul className={styles.listy}>
                    {getAnswerTotals().map((item, index) => (
                      <li key={index}>
                        <div className={styles.listGizzards}>
                          <span className={styles.listHeader}>{item.name}</span>
                          <span>
                            Your Response:{' '}
                            <b
                              className={
                                item.correctAnswer === item.userAnswer
                                  ? 'correct'
                                  : 'incorrect'
                              }
                            >
                              {item.userAnswer}/10
                            </b>
                          </span>
                          <span>
                            Correct Answer: <b>{item.correctAnswer}/10</b>
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.peopleWrap}>
                  {bystanders.map((person, index) => (
                    <button
                      key={person.id}
                      onClick={() => addRole(person.id)}
                      className={`${styles.personWrap} ${
                        styles[person.role + 'Person']
                      }`}
                    >
                      <PersonSVG />
                    </button>
                  ))}
                </div>
                <div>
                  <div className={styles.legend}>
                    {correctAnswers.map((item, index) => (
                      <button
                        key={index}
                        className={
                          item.name === selectedRole
                            ? styles.active
                            : styles.legendWrap
                        }
                        onClick={() => setSelectedRole(item.name)}
                      >
                        <span>{item.name}</span>
                        <div
                          className={`${styles[item.name]} ${
                            styles.circleSelect
                          }`}
                        />
                      </button>
                    ))}
                  </div>
                  <button
                    className={styles.submit}
                    onClick={() => {
                      unlockProgram();
                      setSubmit(true);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ActivityModule;
