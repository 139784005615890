import React, { useEffect } from 'react';
import '../../stylesheets/main.scss';
import Button from './buttons.jsx';
import Title from './Title';

const getClassNames = (title) => {
  const baseClass = 'modalContentTitle';

  switch (title.toLowerCase()) {
    case 'correct!':
      return `correct ${baseClass}`;
    case 'incorrect':
      return `incorrect ${baseClass}`;
    default:
      return baseClass;
  }
};

function Content(props) {
  return (
    <>
      {props.img ? (
        <>
          <div className='modalContentImages'>
            {props.text.title && (
              <Title isModal>
                <h1 className={getClassNames(props.text.title)}>
                  {props.text.title}
                </h1>
              </Title>
            )}
            {props.text.content && (
              <div className='type-of-bullies-wrap'>
                <div id='modalTextImages' className='descriptionBox'>
                  {props.text.content}
                  {props.text.examples ? (
                    <>
                      <br /> <br />
                      {props.text.examples}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div id='imageContainer'>{props.img}</div>
              </div>
            )}
            {props.text.list && (
              <div className='type-of-bullies-wrap'>
                <div id='modalTextImages' className='descriptionBox'>
                  <ul>
                    {props.text.list.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
                <div id='imageContainer'>{props.img}</div>
              </div>
            )}
            <Button text='Close' type='optionsClose' click={props.close} />
          </div>
        </>
      ) : (
        <>
          <div className='modalContent'>
            {props.text.title && (
              <div className='modal-T'>
                <h1 className={getClassNames(props.text.title)}>
                  {props.text.title}
                </h1>
              </div>
            )}
            <ul>
              {typeof props.text.content === 'object' ? (
                props.text.content.map((item, index) => {
                  return <li className='modalText'>{item}</li>;
                })
              ) : (
                <p className='modalText'>{props.text.content}</p>
              )}
            </ul>

            {props.activity ? <div>{props.activity}</div> : <></>}
            {props.img}
            <Button text='Close' type='optionsClose' click={props.close} />
          </div>
        </>
      )}
    </>
  );
}
export default Content;
