import React from 'react';
import { useAvatar } from '../../../hooks/AvatarContext';
import AvatarSlider from './AvatarSlider';
const AvatarSecond = () => {
  const { selectedAvatar } = useAvatar();

  const baseSlides = [
    {
      title: 'Main Program 1',
      image: {
        url: '/avatars/scenes/scene2/b1/story/1.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-2/base/1.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene2/b1/story/2.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-2/base/2.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene2/b1/story/3.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-2/base/3.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene2/b1/story/4.png',
      },
      type: 'wwyd',
    },
  ];

  const responseOptions = [
    {
      title: 'Stealing The Show',
      esTitle: 'Robar el Espectáculo',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Stealing The Show',
          image: {
            url: '/avatars/scenes/scene2/b1/steal/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/steal/1.mp3',
            },
          ],
        },
        {
          title: 'Stealing The Show',
          image: {
            url: '/avatars/scenes/scene2/b1/steal/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/steal/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene2/teacher.png',
          },
          text: 'Excellent choice! Using Stealing the Show works well for students who are outgoing and like being the center of attention. Also, if you like being goofy, you can interrupt the situation by doing a silly dance. When you do that, your peers will look at you and look away from the bullying situation.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/steal/3.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Turning It Over',
      esTitle: 'Pásaselo a alguien',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene2/b1/turn/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/turn/1.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene2/b1/turn/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/turn/2.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene2/b1/turn/3.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/turn/3.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene2/teacher.png',
          },
          text: 'Great choice! You can always let an adult at school know when you see bullying behaviors and you are not comfortable getting directly involved. ',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/turn/4.mp3',
            },
          ],
        },
      ],
    },
    {
      completed: false,
      active: false,
      title: 'Accompanying Others ',
      esTitle: 'Acompañar a otros',
      slides: [
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene2/b1/accompany/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/accompany/1.mp3',
            },
          ],
        },
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene2/b1/accompany/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/accompany/1.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene2/teacher.png',
          },
          text: 'Good choice! Accompanying Others lets Jake know that someone else at school – in this case you – cares about him and thinks what happened is not okay. You can also use Accompanying Others by walking with Jake to his class. Just being there without talking about what happened can feel more comfortable for some students.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/accompany/3.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Coaching Compassion',
      esTitle: 'Entrenamiento de Compasión',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene2/b1/coach/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/coach/1.mp3',
            },
          ],
        },
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene2/b1/coach/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/coach/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene2/teacher.png',
          },
          text: 'Coaching Compassion is an excellent choice if you are older, friends with the students who are wanting to give Jake a hard time, or you think the students who are about to spread a rumor will listen to you and respect you. You can say something quick, like, “Stop!” or “Cut it out” and often other students will listen.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-2/coach/3.mp3',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <AvatarSlider
        storySlides={baseSlides}
        responseOptions={responseOptions}
        title={'Relationship Bullying'}
      />
    </div>
  );
};

export default AvatarSecond;
