import React, { useEffect } from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPointsWithAudio } from './FadeInBulletPointsWithAudio';
import useAudioAndText from '../../../hooks/useAudioAndText';
import { useLocked } from '../../hooks/ContinueProvider';
import { useStore } from '../../../hooks/zustand';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';
import { FadeInBulletPoints } from './FadeInBulletPoints';

function Intro3() {
  useUnlockAfterAudioPlays();
  return (
    <>
      <Title>
        <h1>What Is Bullying?</h1>
      </Title>
      <div className='bullet-img-wrap'>
        <FadeInBulletPointsWithAudio />
        <div className='img-wrap'>
          <img alt='bullying' src='/whatIsBullying.png' />
        </div>
      </div>
    </>
  );
}
export default Intro3;
