import React from 'react';
import { useAvatar } from '../../../hooks/AvatarContext';
import { v4 as uuidv4 } from 'uuid';
import AllAvatars from '../intro/AllAvatars';
import AvatarSlider from './AvatarSlider';
const AvatarFirst = () => {
  const { selectedAvatar } = useAvatar();

  const baseSlides = [
    {
      title: 'Main Program 1',
      image: {
        url: '/avatars/scenes/scene1/b1/story/1.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-1/base/1.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene1/b1/story/2.png',
      },
      type: 'main',
      audio: [
        {
          audio: '/audio/avatar-scenario-1/base/2.mp3',
        },
      ],
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene1/b1/story/3.png',
      },
      audio: [
        {
          audio: '/audio/avatar-scenario-1/base/3.mp3',
        },
      ],
      type: 'main',
    },
    {
      title: 'Main Program 2',
      image: {
        url: '/avatars/scenes/scene1/b1/story/4.png',
      },
      type: 'wwyd',
    },
  ];

  const responseOptions = [
    {
      title: 'Stealing The Show',
      esTitle: 'Robar el Espectáculo',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene1/teacher.png',
          },
          text: "Stealing the Show is not an appropriate strategy for cyberbullying. You don’t want to get involved because it can encourage the bully to continue and the student who is being targeted can feel embarrassed because other peers are seeing what is happening. Don't post anything online or engage with the bully's post. Instead use Turning it Over! That is the most important strategy to use in this situation.",
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/steal/1.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Turning It Over',
      esTitle: 'Pásaselo a alguien',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene1/b1/turn/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/turn/1.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene1/b1/turn/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/turn/2.mp3',
            },
          ],
        },
        {
          title: 'Turning It Over',
          image: {
            url: '/avatars/scenes/scene1/b1/turn/3.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/turn/3.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene1/teacher.png',
          },
          text: 'Excellent choice! Letting an adult know when you see cyberbullying is the best action you can take. Even though it did not happen at school, the adults at school can help! Take a screenshot or a picture of the post right away before the bully takes it down. That way you can turn it in to an adult at school. Remember that sometimes adults can be working on a situation behind the scenes after you report cyberbullying, and you may not know that what you did is really helping.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/turn/4.mp3',
            },
          ],
        },
      ],
    },
    {
      completed: false,
      active: false,
      title: 'Accompanying Others ',
      esTitle: 'Acompañar a otros',
      slides: [
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene1/b1/accompany/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/accompany/1.mp3',
            },
          ],
        },
        {
          title: 'Accompanying Others',
          image: {
            url: '/avatars/scenes/scene1/b1/accompany/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/accompany/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene1/teacher.png',
          },
          text: 'Good choice! By Accompanying Others and smiling and being friendly to the student who was targeted, you can help her feel a little bit better. It might be helpful to Lea to know that there is another student at school who is nice and cares about her. Your simple actions can help her feel less alone.',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/accompany/3.mp3',
            },
          ],
        },
      ],
    },
    {
      title: 'Coaching Compassion',
      esTitle: 'Entrenamiento de Compasión',
      completed: false,
      active: false,
      slides: [
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene1/b1/coach/1.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/coach/1.mp3',
            },
          ],
        },
        {
          title: 'Coach',
          image: {
            url: '/avatars/scenes/scene1/b1/coach/2.png',
          },
          type: 'turn',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/coach/2.mp3',
            },
          ],
        },
        {
          title: 'Main Program 2',
          image: {
            url: '/avatars/scenes/scene1/teacher.png',
          },
          text: 'Excellent choice! Remember to only use Coaching Compassion if you are older, friends with the bullies, or think they will respect you. If you can stop the students from making fun of Lea, that would be an excellent way to act as a defender and make a positive difference at your school and for your peer who is being targeted. You can say something quick like, “Stop!”',
          type: 'end',
          audio: [
            {
              audio: '/audio/avatar-scenario-1/coach/3.mp3',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <AvatarSlider
        storySlides={baseSlides}
        responseOptions={responseOptions}
        title={'Cyberbullying'}
      />
    </div>
  );
};

export default AvatarFirst;
