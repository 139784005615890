import React from 'react';
import Questionaire from '../../shared/Questionaire';
import { useEffect } from 'react';
import Title from '../../shared/Title';
import { gsap } from 'gsap';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { useLocked } from '../../hooks/ContinueProvider';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';
import LanguageToggle from '../../shared/LanguageToggle';
import { useStore } from '../../../hooks/zustand';
import { current } from 'immer';

const audio = [
  {
    audio: '/booster/audio/intro/1.mp3',
    type: 'title',
  },
  {
    audio: '/booster/audio/intro/2.mp3',
    text: 'Review the 4 STAC strategies.',
    type: 'bullet',
  },
  {
    audio: '/booster/audio/intro/3.mp3',
    text: 'Practice using each of the strategies so you can become more comfortable intervening when you see bullying at school or online.',
    type: 'bullet',
  },
  {
    audio: '/booster/audio/intro/4.mp3',
    text: 'Complete an activity where you track the types of bullying situations you saw and what strategies you used to act as a defender since last time you worked on this online program.',
    type: 'bullet',
  },
];

function BoosterIntro() {
  useUnlockAfterAudioPlays();
  const { language, eject, load } = useStore((state) => {
    return {
      language: state.language,
      eject: state.eject,
      load: state.load,
      currentIndex: state.currentIndex,
    };
  });

  useEffect(() => {
    eject();
    load(audio, 'ALL');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    gsap.from('.intro2Box', {
      bottom: '-100em',
      duration: 0.5,
    });
    gsap.from('.mainNavBubble', {
      left: '1000px',
    });
  }, []);

  const { unlockProgram } = useLocked();

  return (
    <>
      <Title>
        <div>
          <h1>Welcome Back!</h1>
        </div>
      </Title>
      <div className='card-container'>
        <LanguageToggle />
        <div className='flex-boost'>
          <div className='make-bigger'>
            <div className='teacherContainer'>
              <div className='mainNavBubble'>
                <SpeechBubble tween={{}}>
                  <div>Welcome back to the STAC Training!</div>
                </SpeechBubble>
              </div>
              <Trainer teacher={2} />
            </div>
          </div>
          <div className='teo'>
            <p className='today'>Today You Will</p>
            <FadeInBulletPointsWithAudio resetIndex={language} />
          </div>
        </div>
      </div>
    </>
  );
}
export default BoosterIntro;
