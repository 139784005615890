import React, { useEffect, useRef } from 'react';
import Title from '../../shared/Title';
import MM from './b1.png';
import { useState } from 'react';
import Trainer from '../../shared/Trainer';
import SpeechBubble from '../../shared/SpeechBubble';
import { useLocked } from '../../hooks/ContinueProvider';
import { useAvatar } from '../../../hooks/AvatarContext';

const Avatars = [
  {
    id: 'b1',
    src: 'selection_b1.png',
    description: 'lorem10',
  },
  {
    id: 'b2',
    src: 'selection_b2.png',
    description: 'lorem10',
  },
  {
    id: 'b3',
    src: 'selection_b3.png',
    description: 'lorem10',
  },
  {
    id: 'g1',
    src: 'selection_g1.png',
    description: 'lorem10',
  },
  {
    id: 'g2',
    src: 'selection_g2.png',
    description: 'lorem10',
  },
  {
    id: 'g3',
    src: 'selection_g3.png',
    description: 'lorem10',
  },
];

const AllAvatars = () => {
  // const [avatar, setAvatar] = useState(Avatars);
  const { unlockProgram, lockProgram } = useLocked();
  const { selectedAvatar, saveAvatar, removeAvatar } = useAvatar();
  const buttonsRefs = useRef([]);
  const [flash, setFlash] = useState(false);

  const selectAvatar = (id) => {
    if (selectedAvatar === id) {
      removeAvatar();
    } else {
      saveAvatar(id);
    }
  };

  useEffect(() => {
    if (selectedAvatar) {
      unlockProgram();
    } else {
      console.log('lock');
      lockProgram();
    }
  }, [lockProgram, selectedAvatar, unlockProgram]);

  return (
    <div>
      <Title>
        <h1>Choose an Avatar!</h1>
      </Title>
      <div className='avatar-slide'>
        <div className='teacherContainer'>
          <div className='mainNavBubble'>
            <SpeechBubble tween={{}}>
              <div>
                Now that you have learned the four STAC strategies, practice
                using them in bullying situations. Choose a character to
                represent you as a defender when you see bullying at school.
                Then click on the Next button to begin.
              </div>
            </SpeechBubble>
          </div>
          <Trainer teacher={4} />
        </div>
        <div className='avatar-wrapper'>
          <div className='av-grid'>
            {Avatars.map((avatar, index) => (
              <button
                key={avatar.id}
                className={`av-wrap ${
                  avatar.id === selectedAvatar ? 'selected' : ''
                } ${flash === avatar.id ? 'flash' : ''} `}
                onClick={() => {
                  setFlash(avatar.id);
                  selectAvatar(avatar.id);
                }}
              >
                <img
                  className='avatar'
                  src={`/avatars/selection/${avatar.src}`}
                  alt={avatar.description}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllAvatars;
