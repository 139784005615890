import React, { Fragment, useEffect, useState } from 'react';
import styles from './KnowledgeCheck.module.scss';
import wrong from './wrong.png';
import { v4 as uuidv4 } from 'uuid';
import Title from '../../shared/Title';
import correct from './correct.png';
import { useLocked } from '../../hooks/ContinueProvider';
import { useFireworks } from '../../../hooks/FireWorksProvider';
import { useStore } from '../../../hooks/zustand';
import { set } from 'react-hook-form';

const KnowledgeCheck = ({ initialState, options, type, prompt }) => {
  return (
    <>
      <Title>
        <h1>Knowledge Check</h1>
      </Title>
      <Quiz
        initialState={initialState}
        options={options}
        type={type}
        prompt={prompt}
      />
    </>
  );
};

export const Quiz = ({
  initialState,
  options,
  type,
  prompt,
  noWrongAnswers = false,
}) => {
  const { setExplosion } = useFireworks();
  const { unlockProgram } = useLocked(true);
  const [formInfo, setFormInfo] = useState(
    initialState.map((item) => ({ ...item, id: uuidv4() }))
  );
  const [titleRead, setTitleRead] = useState(false);

  const { allSoundsPlayed, load, isPlaying, hasAudio } = useStore((state) => {
    return {
      allSoundsPlayed: state.allSoundsPlayed,
      load: state.load,
      isPlaying: state.isPlaying,
      hasAudio: state.audioArray.length > 0,
    };
  });

  const audioFiles = formInfo.map((item) => item.audio);

  useEffect(() => {
    if (!titleRead && allSoundsPlayed) {
      setTitleRead(true);
    }
    if (!hasAudio) {
      setTitleRead(true);
    }
  }, [allSoundsPlayed, audioFiles, hasAudio, load, titleRead]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const updatedFormInfo = formInfo.map((item) => {
      if (item.description === name) {
        //set fireworksgif to true id answer is correct
        if (item.type === value) {
          console.log('correct answer');
          setExplosion(true);
        }

        return { ...item, answer: value };
      } else {
        return item;
      }
    });
    setFormInfo(updatedFormInfo);
  };

  const [slideIndex, setSlideIndex] = useState(0);

  console.log(isPlaying);

  const getQuiz = (item) => {
    console.log(item.answer, 'what is the answer');
    switch (type) {
      case 'trueOrFalse':
        return (
          <div className={styles.answerWrap}>
            {options.map((value, index) => {
              return (
                <Fragment key={value}>
                  <label htmlFor={`${item.id + index}`}>{value}</label>
                  <input
                    disabled={!allSoundsPlayed || !titleRead}
                    onClick={
                      //if item is incorrect laod the audio file again
                      item.type !== value
                        ? () => {
                            console.log('wrong answer', audioFiles, slideIndex);
                            if (audioFiles[slideIndex]?.wrong) {
                              load([
                                {
                                  audio: audioFiles[slideIndex].wrong,
                                },
                              ]);
                            }
                          }
                        : null
                    }
                    type='radio'
                    name={item.description}
                    id={`${item.id + index}`}
                    value={value}
                    className={styles.inputStyles}
                    checked={item.answer === value}
                    onChange={handleInputChange}
                  />
                </Fragment>
              );
            })}
          </div>
        );
      case 'dropdown':
        return (
          <select
            onChange={handleInputChange}
            name={item.description}
            id={item.id}
            disabled={!allSoundsPlayed || !titleRead}
          >
            <option
              selected={!item.answer || item.answer === 'not answered'}
              value='fss'
              disabled
              hidden
            >
              Choose An Options
            </option>
            {options.map((value, index) => {
              return (
                <option
                  selected={item.answer === value}
                  key={index}
                  value={value}
                  className={'dropdown'}
                >
                  {value}
                </option>
              );
            })}
          </select>
        );
      default:
        return <></>;
    }
  };

  const [gifVisible, setGifVisible] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (gifVisible) {
      setTimeout(() => {
        setGifVisible(false);
      }, 3500);
    }
  }, [gifVisible]);

  const handleNext = () => {
    if (slideIndex === formInfo.length - 1) {
      setDone(true);
      unlockProgram();
    } else {
      setSlideIndex(slideIndex + 1);

      const isLastSlide = slideIndex + 1 === audioFiles.length - 1;
      console.log(
        isLastSlide,
        'is last slide',
        audioFiles.length - 1,
        slideIndex + 1
      );
      if (audioFiles[slideIndex + 1]?.question) {
        console.log(
          'loading next audio file',
          audioFiles[slideIndex + 1].question
        );

        load([
          {
            audio: audioFiles[slideIndex + 1].question,
          },
        ]);
      }
    }
  };

  const checkIfButtonShouldBeDisabled = (
    userAnswer,
    correctAnswer,
    soundsAreDone
  ) => {
    console.log(userAnswer, correctAnswer, soundsAreDone, 'sam');
    if (userAnswer === 'not answered') {
      return true;
    }

    if (correctAnswer) {
      if (userAnswer === correctAnswer) {
        return false;
      } else {
        return true;
      }
    }
    if (soundsAreDone) {
      return false;
    }
    return true;
  };

  if (done) {
    return (
      <div className={styles.finishedWrapper}>
        <div className={styles.innerWrap}>
          <div className={styles.done}>
            <h2>Great Job!</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.outerWrapper}>
        <div className={styles.innerWrap}>
          <form className={styles.formStyles}>
            <div className={styles.headyW}>
              <h2 className={styles.heady}>{prompt}</h2>
              <div className={styles.index}>
                <div>
                  {slideIndex + 1} / {formInfo.length}
                </div>
              </div>
            </div>
            <div>
              <fieldset key={formInfo[slideIndex].id} className={styles[type]}>
                <div className={styles.rightWrongWrapper}>
                  {formInfo[slideIndex].type &&
                    formInfo[slideIndex].answer !== 'not answered' && (
                      <div className={styles.checkWrapper}>
                        {formInfo[slideIndex].answer ===
                        formInfo[slideIndex].type ? (
                          <>
                            <img src={correct} alt='correct answer' />
                            {gifVisible && (
                              <img
                                className={styles.fireworks}
                                src='/fireworks.gif'
                                alt='Your GIF'
                              />
                            )}
                          </>
                        ) : (
                          <img src={wrong} alt='incorrect answer' />
                        )}
                      </div>
                    )}
                  <div>
                    <legend>{formInfo[slideIndex].description}</legend>

                    {formInfo[slideIndex].type &&
                      formInfo[slideIndex].answer !== 'not answered' &&
                      formInfo[slideIndex].answer !==
                        formInfo[slideIndex].type && (
                        <div className={styles.tellCorrectAnswerWrapper}>
                          <div className={styles.tellCorrectAnswer}>
                            {!formInfo[slideIndex].inccorectResponse ? (
                              <>
                                <span className={styles.correctAnswer}>
                                  Incorrect – Try Again!
                                </span>
                              </>
                            ) : (
                              <span
                                className={styles['answerHighliteFeedback']}
                              >
                                {formInfo[slideIndex].inccorectResponse}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {getQuiz(formInfo[slideIndex])}
              </fieldset>
              <div className={styles.nextWrapper}>
                <button
                  type='button'
                  onClick={handleNext}
                  disabled={checkIfButtonShouldBeDisabled(
                    formInfo[slideIndex].answer,
                    formInfo[slideIndex].type,
                    allSoundsPlayed
                  )}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeCheck;
