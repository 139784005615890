import React from 'react';
import Title from '../../shared/Title';
import Badge from './Badge';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

function Coach() {
  const allSoundsPlayed = useUnlockAfterAudioPlays();
  return (
    <>
      {allSoundsPlayed && (
        <Badge
          imgSrc='/badges/C-Badge.png'
          imgAlt='Coaching Compassion'
          endTop={'32px'}
          endRight={'10px'}
        />
      )}
      <>
        <Title>
          <h1>Coaching Compassion</h1>
        </Title>
        <p className='banner-p'>
          The defender lets the students who bully know that what they are doing
          is not okay and encourages them to think about what it would feel like
          to be in the target’s shoes.
        </p>
        <div className='strat-wrap'>
          <FadeInBulletPointsWithAudio ulClasses={'strategy'} />
          <div className='img-wrap-strat'>
            <img
              alt='Accompanying Others'
              className='img-strat'
              src='/strategies/coaching_compassion.png'
            ></img>
          </div>
        </div>
      </>
    </>
  );
}
export default Coach;
