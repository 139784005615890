import { Booster, Wrapper } from './Booster';
import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import './booster.scss';
import WhoToTurnTo from '../intro/WhoToTurnTo';
import BoosterIntro from './BoosterIntro2';
import STACStrategies from './STACStrategies';
import PracticeVideoModule from './1-1';
import EndSlide from './EndSlide';
import Tracker from './Tracker';
import WhoToTurnTo3 from '../intro/WhoToTurnTo3';
import KnowledgeCheck from '../intro/KnowledgeCheck';

const knowledgeCheckMod4 = {
  prompt: 'Mark each item as true or false.',
  options: ['true', 'false'],
  questions: [
    {
      type: 'true',
      description:
        'A defender can Steal the Show by distracting the bully by acting silly or starting a conversation.',
      answer: 'not answered',
    },
    {
      type: 'false',
      description:
        'Defenders should use Stealing the Show and get involved if they see a physical fight.',
      answer: 'not answered',
    },
    {
      type: 'true',
      description:
        'Defenders can use Accompanying Others by inviting a classmate who was bullied to have lunch together.',
      answer: 'not answered',
    },
    {
      type: 'false',
      description:
        'It is appropriate to Turn Over a bullying situation to another student at school.',
      answer: 'not answered',
    },
    {
      type: 'true',
      description:
        'Defenders could use Coaching Compassion if they are older or good friends with the bully or think the bully will respect them.',
      answer: 'not answered',
    },
  ],
};

const selectionItems1 = [
  {
    title: 'Stealing the Show',
    defender: {
      text: 'The defender interrupts the girl spreading the rumor at the bus stop by pointing and saying to the group, “Look! There is a really cute dog over there."',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'Great choice! Stealing the Show is a good strategy for students who are outgoing and aren’t afraid to be the center of attention. By Stealing the Show, you are interrupting the bullying situation and moving your peers’ attention away from the bully and the target and placing it on you, but in a safe manner. Another thing you could do to interrupt the group from spreading a rumor is to tell a joke or act goofy by doing a silly dance.. Now check out other strategies so that you can have options!',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'The defender walks up to an adult by the bus stop and lets the adult know what is going on by saying, “I wanted to let you know what’s going on. A group of girls is planning to spread a rumor about someone. I am not sure what to do, but I know it’s not okay.”',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'Good idea to tell an adult. You can always ask for adult help when you see something going on that you think is not okay and can be hurtful to one of your peers. Now, click on the other strategies to see how you could use those strategies in this situation.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'The defender walks up to the girl who had the rumor spread about her (she looks upset like she knows what happened), and says, “I know it’s not true that you cheated on the test. I will tell people that it’s not true.”',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'Accompanying Others is a great choice! When you express concern for your peers, they will probably feel like someone at school cares about them, and it can make a really big difference. It does not take much to help someone out by being a friend or even just friendly to them. Now check out the other strategies so that you know how to use other strategies, too! Stealing the Show and Coaching Compassion are also great options.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'The defender speaks to the girls spreading the rumor by saying, “I’ve had rumors spread about me and it was really hard. Rumors can make people feel really bad. So, let’s not do this.”',
      imgUrl: '/booster/g1_1_animated.gif',
    },
    teacher: {
      text: 'If you are older, friends with the girls who are going to spread the rumor, or think those girls will listen to you and respect you, Coaching Compassion is an excellent choice. You can also just say something quick like “Don’t do that. It’s not okay”, and often students will listen. Now, click on the other strategies to see how you could use those, too.',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

const selectionItems2 = [
  {
    title: 'Stealing the Show',
    teacher: {
      text: 'When you see cyberbullying, you do not want to engage by Stealing the Show. It can make the situation worse to give it more  attention. However, do use a different strategy. Turning it Over is the best choice when you see cyberbullying!',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Turning It Over',
    defender: {
      text: 'The defender takes a screenshot of the post, and shows it to a  trusted adult at school the next day.',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'This is a great choice of strategy! Letting a trusted adult know when you see cyberbullying is the best action you can take. Even when bullying happens online, the adults at school can help! Go ahead and click on the other strategies to see how they play out. You could follow this one up with Accompanying Others or Coaching Compassion.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Accompanying Others',
    defender: {
      text: 'The defender crosses paths with the student who was made fun of (and the student looks sad); the defender makes an effort to say hi and smile.',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'Good choice. By Accompanying Others and smiling and being friendly to the student who was targeted, you can help her feel more uplifted and like there is someone who is nice and cares about her at school. It can help her feel less alone. Remember to also always use Turning it Over when you see cyberbullying. Now click on other strategies, starting with Turning it Over, to see how to use them.',
      imgUrl: '/booster/teacher.gif',
    },
  },
  {
    title: 'Coaching Compassion ',
    defender: {
      text: 'The defender goes up to the students who are filming and says, “That’s not cool. You should delete those posts. Also, it can get you in big trouble.”',
      imgUrl: '/booster/defender.gif',
    },
    teacher: {
      text: 'This is a good choice! Remember to only use Coaching Compassion if you are older, friends with the bullies or think they will respect you. If you are able to stop those girls from making a video and posting it, that would be an excellent way to act as a defender and make a positive difference at your school and for your peer who is being targeted. Remember to also always use Turning it Over when you see cyberbullying. Now, click on the other strategies to see how to use them.',
      imgUrl: '/booster/teacher.gif',
    },
  },
];

const routesArray = [
  {
    url: 'intro',
    component: <BoosterIntro />,
    locked: true,
  },
  {
    url: 'stac-strategies',
    component: <STACStrategies />,
    locked: true,
  },
  {
    url: 'scenario-1',
    component: (
      <PracticeVideoModule
        selectionItems={selectionItems1}
        title={'Practice Time 3'}
        videoSrc={'/booster/cheater'}
        defenderImg={'/booster/g1_1_animated.gif'}
        prize={1}
      />
    ),
    locked: true,
  },
  {
    url: 'scenario-2',
    component: (
      <PracticeVideoModule
        selectionItems={selectionItems2}
        title={'Practice Time 4'}
        videoSrc={'/booster/faceFood'}
        defenderImg={'/booster/b1_1_animated.gif'}
        prize={2}
      />
    ),
    locked: true,
  },
  // {
  //   url: 'who-to-turn-to-2',
  //   component: <WhoToTurnTo />,
  //   locked: true,
  // },
  {
    url: 'knowledge-check',
    locked: true,
    component: (
      <KnowledgeCheck
        prompt={knowledgeCheckMod4.prompt}
        initialState={knowledgeCheckMod4.questions}
        options={knowledgeCheckMod4.options}
        type={'trueOrFalse'}
        popUp={true}
      />
    ),
  },
  {
    url: 'tracker',
    component: <Tracker />,
    locked: true,
  },
  {
    url: 'end',
    component: <EndSlide />,
    locked: false,
  },
];

function BoosterTwo() {
  return (
    <Wrapper>
      <Booster path={'booster-two'} routesArray={routesArray} />
    </Wrapper>
  );
}

export default BoosterTwo;
