import React from 'react';
import Title from '../../shared/Title';

function Intro1() {
  return (
    <>
      <Title>
        <div className='title'>
          <h1>Learning to Stop Bullying at School</h1>
        </div>
      </Title>

      <ul className='introQuery '>
        <li>
          In this training, you will learn how to act as a “defender” to stop
          negative and aggressive behavior while it is happening.
        </li>
        <li>
          You will also learn to help your peers who are targets of bullying and
          communicate to students who bully that their behavior is not
          acceptable.
        </li>
        <li>
          You will learn how to use 4 STAC strategies to accomplish this goal.
        </li>
        <li>
          You will learn to focus on the action of your peers and not on the
          student who is bullying someone.
        </li>
      </ul>
      <div className='logo-wrapper'>
        <img
          className='intro1logo'
          alt='stac training'
          src={require('../../../assets/images/logoSmall.svg')}
        />
      </div>
    </>
  );
}
export default Intro1;
