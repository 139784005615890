import React from 'react';
import Title from '../../shared/Title';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

function DefenderFacts() {
  useUnlockAfterAudioPlays();

  return (
    <>
      <Title>
        <h1 className='titleQuery'>Why Does Your School Need Defenders?</h1>
      </Title>
      <div className='bullet-img-wrap'>
        <FadeInBulletPointsWithAudio />
        <div className='img-wrap-portait'>
          <img alt='bullying' src='/whyNeedDefender.png' />
        </div>
      </div>
    </>
  );
}
export default DefenderFacts;
