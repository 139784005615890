import React, { useEffect, useState } from 'react';
import Button from './buttons.jsx';
import { createPortal } from 'react-dom';
import Content from './modalContent.jsx';
import TouchableContent from './touchableContent.jsx';
import ActivityModal from '../pages/bystanders/Activity/ActivityModal.jsx';
import { useStore } from '../../hooks/zustand.js';
import { fa } from 'faker/lib/locales.js';

function Questionaire(props) {
  // I take 4 props -- modalText, buttonText, buttonType, modalType
  // activity? I take props.children
  // modalText shoud be an object with two key value pairs that are "title" and "content"
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { load, eject, allSoundsPlayed } = useStore((state) => {
    return {
      load: state.load,
      eject: state.eject,
      allSoundsPlayed: state.allSoundsPlayed,
    };
  });

  console.log(props.hasBeenClicked, 'hasBeenClicked');

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [load, modalIsOpen, props.audio]);

  const closeModal = () => {
    if (props.audio) {
      if (allSoundsPlayed) {
        setModalIsOpen(false);
      }
    } else {
      setModalIsOpen(false);
    }
  };

  console.log('modalIsOpen', props.disabled);

  return (
    <>
      <></>
      {modalIsOpen &&
        (!props.modalText.list
          ? createPortal(
              <div
                className='greyback'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log('close');
                  closeModal();
                }}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  {props.modalType === 'activity' ? (
                    <ActivityModal
                      {...props}
                      data={props.data}
                      close={() => closeModal()}
                    >
                      {props.children}
                    </ActivityModal>
                  ) : props.img ? (
                    <Content
                      close={() => closeModal()}
                      text={props.modalText}
                      img={props.img}
                    />
                  ) : props.modalType === 'touchable' ? (
                    <TouchableContent
                      text={props.modalText}
                      close={() => closeModal()}
                      link={props.link}
                    />
                  ) : (
                    <Content
                      modalSize={props.modalSize}
                      close={() => closeModal()}
                      activity={props.children}
                      text={props.modalText}
                    />
                  )}
                </div>
              </div>,
              document.body
            )
          : createPortal(
              <div
                className='greyback'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeModal();
                }}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  {props.modalType === 'activity' ? (
                    <ActivityModal
                      {...props}
                      data={props.data}
                      close={() => closeModal()}
                    >
                      {props.children}
                    </ActivityModal>
                  ) : props.img ? (
                    <Content
                      close={() => closeModal()}
                      text={props.modalText}
                      img={props.img}
                    />
                  ) : props.modalType === 'touchable' ? (
                    <TouchableContent
                      text={props.modalText}
                      close={() => closeModal()}
                      link={props.link}
                    />
                  ) : (
                    <Content
                      modalSize={props.modalSize}
                      close={() => closeModal()}
                      activity={props.children}
                      text={props.modalText}
                    />
                  )}
                </div>
              </div>,
              document.body
            ))}

      {props.progress ? (
        <Button
          {...props}
          click={(e) => {
            setModalIsOpen(true);
            if (props.onClick) {
              props.onClick();
            }
            if (props.audio) {
              console.log('loading', props.audio);
              load(props.audio);
            }
          }}
          type={props.buttonType}
          hasBeenClicked={props.hasBeenClicked}
          text={props.buttonText}
          style={props.style}
          disabled={props.disabled || false}
        />
      ) : (
        <Button
          {...props}
          click={(e) => {
            setModalIsOpen(true);
            if (props.onClick) {
              props.onClick();
            }
            if (props.audio) {
              console.log('loading', props.audio);
              eject();
              load([
                {
                  audio: props.audio,
                },
              ]);
            }
          }}
          type={props.buttonType}
          text={props.buttonText}
          hasBeenClicked={props.hasBeenClicked}
          style={props.style}
          disabled={props.disabled || false}
        />
      )}
    </>
  );
}

export default Questionaire;
