import React from 'react';
import SpeechBubble from '../../shared/SpeechBubble';
import Trainer from '../../shared/Trainer';
import Title from '../../shared/Title';
import styles from './avatarintro.module.scss';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';

export const AvatarIntro = ({ title, bubbleText }) => {
  useUnlockAfterAudioPlays();
  return (
    <>
      <Title>
        <h1>{title}</h1>
      </Title>
      <div className={styles.centerTeacher}>
        <div className='teacherContainer'>
          <div className='mainNavBubble'>
            <SpeechBubble tween={{}}>
              <div>{bubbleText}</div>
            </SpeechBubble>
          </div>
          <Trainer teacher={4} />
        </div>
      </div>
    </>
  );
};
