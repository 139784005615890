import React from 'react';

const PlayButton = () => {
  return (
    <svg
      id='Layer_2'
      data-name='Layer 2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 926.52 926.7'
    >
      <defs></defs>
      <g id='Layer_1-2' data-name='Layer 1'>
        <g>
          <path
            className='cls-1'
            d='m926.02,463.43c0,255.45-207.07,462.78-462.74,462.78S.5,718.88.5,463.43,207.57.5,463.28.5s462.74,207.25,462.74,462.93Z'
          />
          <polygon
            fill='#fff'
            points='368.86 248.21 660.54 463.43 368.86 678.42 368.86 248.21'
          />
        </g>
      </g>
    </svg>
  );
};

export default PlayButton;
