import React, { useState } from 'react';
import Container from './Container';
import Trainer from '../../../shared/Trainer';
import SpeechBubble from '../../../shared/SpeechBubble';
import { gsap } from 'gsap';
import Title from '../../../shared/Title';
import { useLocked } from '../../../hooks/ContinueProvider';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useStore } from '../../../../hooks/zustand';
import { useUnlockAfterAudioPlays } from '../../../../hooks/useUnlockAfterAudioPlays';

function Index(props) {
  const correctAnswer = [3, 1, 4, 2];
  const [submited, setSubmited] = useState(false);
  const { unlockProgram } = useLocked();

  useUnlockAfterAudioPlays();

  const { setIndex, isPlaying, currentIndex, language } = useStore((state) => {
    return {
      currentIndex: state.currentIndex,
      setIndex: state.setIndex,
      isPlaying: state.isPlaying,
      language: state.language,
    };
  });

  console.log('currentIndex', currentIndex);

  const typesOfBullying = [
    {
      id: 1,
      text: props.data.BullyFacts.answer1.title,
      esText: props.data.BullyFacts.answer1.esTitle,
      percentage: 16,
    },
    {
      id: 2,
      text: props.data.BullyFacts.answer2.title,
      esText: props.data.BullyFacts.answer2.esTitle,
      percentage: 9,
    },
    {
      id: 3,
      text: props.data.BullyFacts.answer3.title,
      esText: props.data.BullyFacts.answer3.esTitle,
      percentage: 20,
    },
    {
      id: 4,
      text: props.data.BullyFacts.answer4.title,
      esText: props.data.BullyFacts.answer4.esTitle,
      percentage: 12,
    },
  ];

  const correctPlay = () => {
    setSubmited(true);
    gsap.to('.dragDrogWrapper', 0.5, { autoAlpha: 0, display: 'none' });
    gsap.to('#beforeAnsweredText', 0.5, { autoAlpha: 0, display: 'none' });

    gsap.to('.answerWrap', 0.5, { autoAlpha: 1, display: 'block' }).delay(0.5);
    gsap
      .to('#answeredText', 0.5, { autoAlpha: 1, display: 'block' })
      .delay(0.5);
  };

  const submit = () => {
    console.log('submit');
    setSubmited(true);
    setIndex(1);
    correctPlay();
  };

  const dataLabel =
    language === 'en'
      ? [
          ['Being made fun', 'of or being ', 'called names'],
          'Spreading rumors',
          'Cyberbullying',
          ['Being pushed or', 'shoved'],
        ]
      : [
          ['Ser burlado', 'o insultado'],
          'Difundir rumores',
          'Ciberacoso',
          ['Ser empujado o', 'derribad'],
        ];

  return (
    <>
      <Title>
        <h1>Ranking Activity</h1>
      </Title>
      <div className='card-container'>
        <div>
          <div className='teacherContainer'>
            <div className='mainNavBubble'>
              <SpeechBubble tween={{}}>
                <div id='beforeAnsweredText'>
                  <p>
                    Reorder the buttons to rank what you think are the most
                    common types of bullying from top to bottom.
                  </p>
                </div>
                <div id='answeredText' className='answerTextWrap'>
                  <p style={{ marginBottom: '.5rem' }}>
                    Being made fun of or being called names is the most common
                    type of bullying <b>in most schools.</b> 20% of middle
                    school students report being made fun of or being called
                    names.
                  </p>
                </div>
              </SpeechBubble>
            </div>
            <div className={submited ? 'fade-in-index' : ''}>
              <Trainer teacher={submited ? 5 : 4} />
            </div>
          </div>
        </div>
        <div className='orderBox'>
          <div className='dragDrogWrapper'>
            <p>
              Rank the types of bullying by placing what type you think happens
              most commonly at school on top.
            </p>
            <Container
              initialCardState={typesOfBullying}
              id='orderContainer'
              submit={submit}
              data={props.data}
              isPlaying={currentIndex === 0}
            />
          </div>
          <div>
            <div className='answerWrap'>
              <div className='overflow-container'>
                <div className='chartContainer'>
                  <Bar
                    plugins={[ChartDataLabels]}
                    options={{
                      plugins: {
                        tooltip: {
                          enabled: false,
                        },
                        datalabels: {
                          display: true, // Enable data labels
                          color: 'white', // Font color for data labels
                          anchor: 'center', // Position of data labels (start, center, end)
                          align: 'end', // Text alignment (start, center, end)
                          textStrokeColor: 'white',
                          font: {
                            size: '20',
                            weight: 'bold',
                          },
                          formatter: (value, context) => {
                            return `${value}%`; // Display the value as a percentage
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                      backgroundColor: [
                        '#0352a0',
                        'rgb(239, 51, 64)',
                        'rgba(255, 159, 64)',
                        'rgba(153, 102, 255',
                      ],
                      scales: {
                        y: {
                          display: false,
                          // beginAtZero: true,
                          // ticks: {
                          //   color: 'black',
                          // },
                        },
                        x: {
                          ticks: {
                            color: 'black',
                            font: {
                              size: 14,
                              weight: 'bold',
                            },
                          },
                        },
                      },
                    }}
                    data={{
                      labels: dataLabel,
                      datasets: [
                        {
                          label: 'Percent Bullied',
                          data: typesOfBullying
                            .map((item) => {
                              return item.percentage;
                            })
                            .sort((a, b) => b - a),
                          borderWidth: 2,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
