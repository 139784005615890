import React, { useEffect } from 'react';
import Title from '../../shared/Title';
import { createPortal } from 'react-dom';
import { FadeInBulletPoints } from '../intro/FadeInBulletPoints';
import { FadeInBulletPointsWithAudio } from '../intro/FadeInBulletPointsWithAudio';
import { useUnlockAfterAudioPlays } from '../../../hooks/useUnlockAfterAudioPlays';
import Badge from './Badge';

function Turn() {
  // const achievementReverse = () => {
  //   setTimeout(() => {
  //     gsap.to('.badgeFix', .5, { autoAlpha: 0, height: '0vh' })
  //   }, 2000)
  // }
  // useEffect(() => {
  //   gsap.to('.badgeFix', .5, { autoAlpha: 1, height: '40vh' })
  //   achievementReverse()
  // }, [])

  const allSoundsPlayed = useUnlockAfterAudioPlays();

  const [badge, setBadge] = React.useState(false);

  return (
    <>
      {allSoundsPlayed && (
        <Badge
          imgSrc='/badges/T-Badge.png'
          imgAlt='Turning It Over'
          endTop={'32px'}
          endRight={'130px'}
        />
      )}
      <Title>
        <h1>Turning It Over</h1>
      </Title>
      <p className='banner-p'>
        Students ask for help from a trusted adult at school when they don’t
        feel comfortable intervening.
      </p>
      <div className='strat-wrap'>
        <FadeInBulletPointsWithAudio ulClasses='strategy' />
        <div className='img-wrap-strat'>
          <img
            alt='Turning It Over'
            className='img-strat'
            src='/strategies/turning_it_over.png'
          ></img>
        </div>
      </div>
    </>
  );
}
export default Turn;
